import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { TowersComponent } from './components/towers/towers.component';
import { BuyComponent } from './components/buy/buy.component';
import { LeaseComponent } from './components/lease/lease.component';
import { MediaComponent } from './components/media/media.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TermsComponent } from './components/terms/terms.component';
import { BrokerComponent } from './components/broker/broker.component';
import { JbcComponent } from './components/jbc/jbc.component';
import { BlogComponent } from './components/blog/blog.component';

const routes: Routes = [
	{path:'', component: HomeComponent, data: {title: 'Home'}},
	{path:'aboutus', component: AboutusComponent, data: {title: 'About Us'}},
	{path:'towers', component: TowersComponent, data: {title: 'Towers'}},
	{path:'buy', component: BuyComponent, data: {title: 'Buy'}},
	{path:'lease', component: LeaseComponent, data: {title: 'Lease'}},
	{path:'media', component: MediaComponent, data: {title: 'Media'}},
	{path:'contactus', component: ContactusComponent, data: {title: 'Contact Us'}},
	{path:'terms-conditions', component: TermsComponent, data: {title: 'Terms & Conditions'}},
	{path:'privacy-policy', component: PrivacyComponent, data: {title: 'Privacy Policy'}},
	{path:'property-broker', component: BrokerComponent, data: {title: 'Property Broker'}},
	{path:'jbc/:id/:position', component: JbcComponent, data: {title: 'JBC '}},
	{path:'blog/:id', component: BlogComponent, data: {title: 'Blog '}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
