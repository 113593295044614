import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  //@ViewChild(AppComponent) child;

	scrollHeight: number = 0;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true); //third parameter
  }

  showMenu(){
  	this.renderer.addClass(document.body,'is-menu-visible');
  	this.renderer.setStyle(document.body, 'overflow-y', 'hidden');
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    this.scrollHeight = window.pageYOffset;
  };

}
