<section id="banner" class="major contactus_banner" [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.banner_img">
    <div class="inner">
    </div>
</section>
<!-- Main -->
<div id="main">
    <div class="bg_gray p-5 ">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.contact_us_lable}}</h1>
            </div>
        </div>
    </div>
    <div class="bg_white  p-5">
        <div class="container ">
            <div class="row">
			 <div class="col-sm-7 text-center p-0 mtminus_left onlymobile">
                    <img class="img-fluid p-3" [defaultImage]="'assets/media/images/contact-block-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.contact_block_img">
                </div>
                <div class="col-sm-5 p-3 mt-5 zindex99 arabic-center">
                    <div class="box_white p-3 pfull_0 font-weight-bold text-center border_big">
                        <img class="img-fluid p-0" src="https://jbc-assets.s3.amazonaws.com/images/call.png">
                        <p class="theme_color m-0 f18">{{pageContents?.settings?.phone}}</p>
                        <img class="img-fluid p-0" src="https://jbc-assets.s3.amazonaws.com/images/mail.png">
                        <p class="theme_color m-0  f18"><a class="border-0" href="mailto:{{pageContents?.settings?.email}}">{{pageContents?.settings?.email}}</a></p>
                        <p class="theme_color ml-4 mt-3 mb-3 f16 col-sm-11 mfull_0 pre-wrap">{{pageContents?.activelanguage?.constant_values?.address}}</p>
                    </div>
                </div>
                <div class="col-sm-7 text-center p-0 mtminus_left onlyweb">
                    <img class="img-fluid p-3" [defaultImage]="'assets/media/images/contact-block-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.contact_block_img">
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray p-5">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 border_bottom arabic-center">{{pageContents?.activelanguage?.constant_values?.locations_lable}}</h1>
                <div class="">
                    <ul class="nav nav-pills mb-3  theme_nav nav-fill">
                        <li class="nav-item p-0" (click)="changeTab(1)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 1 }">JBC1</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(2)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 2 }">JBC2</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(3)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 3 }">JBC3</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(4)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 4 }">JBC4</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(5)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 5 }">JBC5</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <div>
                                <google-map height="450" width="100%" [zoom]="zoom" [center]="center" [options]="options">
                                    <map-marker *ngFor="let marker of markers" #somemarker="mapMarker" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfoWindow(somemarker,marker.title)">
                                    </map-marker>
                                    <map-info-window>
                                        <h5 class="title full-width text-dark"> Jumeirah Business Centre - {{activetab}}
                                        </h5>
                                        <div class="address">
                                            <div class="address-line full-width text-muted">Jumeirah Lakes Towers -
                                                Dubai</div>
                                            <div class="address-line full-width text-muted">United Arab Emirates</div>
                                        </div>
                                        <a class="address-line full-width text-primary" target="_blank"
                                            href="{{infoCoords.link}}">View on Google Maps</a>
                                    </map-info-window>
                                </google-map>
                            </div>
                            <div class="theme_bg_color pre-wrap arabic-center">
                                {{ pageContents?.activelanguage?.constant_values['towers_jbc_address_' + activetab] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_white  p-5">
        <div class="container ">
            <div class="row">
                <div class="col-sm-12 text-center arabic-center">
                    <a [routerLink]="['/property-broker']" class="button button_trans mr-4 mt-2"><img class="cus_image" src="https://jbc-assets.s3.amazonaws.com/images/are you property broker.png">{{pageContents?.activelanguage?.constant_values?.are_you_a_property_broker_btn_lable}}</a>
                    <a href="assets/media/docs/JBC-Brochure.pdf" download="JBC-Brochure.pdf" target="_blank" class="button button_trans mr-4  mt-2"><img class="cus_image" src="https://jbc-assets.s3.amazonaws.com/images/download jbc brochure.png">{{pageContents?.activelanguage?.constant_values?.download_brouchure_btn_lable}}</a>
                </div>
            </div>
        </div>
    </div>
</div>