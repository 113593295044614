<div id="main">
    <div class="bg_white p-4 mt-5 ">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color">ARE YOU A PROPERTY BROKER?</h1>
            </div>
        </div>
    </div>
    <div id="broker" class=" bg_white p-3 mb-5">
        <div class="container">
            <form [formGroup]="enquireForm" (ngSubmit)="onSubmit()">
            <fieldset class="primaryborder  p-3">
                <legend class="contact_legent mb-5"><strong class="primary_color">Let us know your details below</strong></legend>
                <div class="row pl-5 pr-5">
                    <div class="col-sm-4 mb-5">
                        <div class="field half">
                            <label class="primary_color" for="company name">Company Name <small class="float-right text-danger" *ngIf="f.name.errors && isSubmitted"> * Required</small></label>
                            <input type="text" formControlName="company_name" [(ngModel)]="formData.company_name" (change)="inputChange()">
                        </div>
                    </div>
                    <div class="col-sm-4 mb-5">
                        <div class="field half">
                            <label class="primary_color" for="name"> NAME <small class="float-right text-danger" *ngIf="f.name.errors && isSubmitted"> * Required</small></label>
                            <input type="text" formControlName="name" [(ngModel)]="formData.name" (change)="inputChange()">
                        </div>
                    </div>
                    <div class="col-sm-4 mb-5">
                        <div class="field half">
                            <label class="primary_color" for="mobile">Mobile Number <small class="float-right text-danger" *ngIf="f.name.errors && isSubmitted"> * Required</small></label>
                            <input type="text" formControlName="mobile" [(ngModel)]="formData.mobile" (change)="inputChange()">
                        </div>
                    </div>
                    <div class="col-sm-4 mb-5">
                        <div class="field half">
                            <label class="primary_color" for="email">Email Address <small class="float-right text-danger" *ngIf="f.name.errors && isSubmitted"> * Required</small></label>
                            <input type="email" formControlName="email" [(ngModel)]="formData.email" (change)="inputChange()">
                        </div>
                    </div>
                    <div class="col-sm-4 mb-5">
                        <div class="field half">
                            <label class="primary_color" for="country">Country <small class="float-right text-danger" *ngIf="f.name.errors && isSubmitted"> * Required</small></label>
                            <input type="text" formControlName="country" [(ngModel)]="formData.country" (change)="inputChange()">
                        </div>
                    </div>
                    <div class="col-sm-4 mb-5">
                        <div class="field half">
                            <label class="primary_color" for="state">City <small class="float-right text-danger" *ngIf="f.name.errors && isSubmitted"> * Required</small></label>
                            <input type="text" formControlName="city" [(ngModel)]="formData.city" (change)="inputChange()">
                        </div>
                    </div>
                    <div class="col-sm-12 text-center mb-3">
                        <button type="submit" id="SUBMIT">Submit</button>
                    </div>
                </div>
            </fieldset>
        </form>
        </div>
    </div>
</div>