import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { WebService } from "../../service/web.service";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  blog:any;
  loading: boolean = true;
  imgBaseUrl: string;
  constructor(private activatedRoute: ActivatedRoute,
		private webService: WebService) { }

  ngOnInit(): void {
    this.imgBaseUrl = this.webService.imgBaseUrl;
    this.activatedRoute.params.subscribe(params => this.getblogInfo(params.id));
  }

  getblogInfo(id) {
    this.webService.blogInfo(id).subscribe(
      resultData => {
        this.blog = resultData;
        this.webService.hideLoader();
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        this.webService.hideLoader();
        this.loading = false;
        if (err.status == 401) {
          alert(err.error.message);
        }
      }
    );
    
	}

}
