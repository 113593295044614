// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  	production: false,
	apiUrl: 'https://jbc.ae/api/web',
  	ImageUrl: 'https://jbc-assets.s3.amazonaws.com/',
  	View360Url: 'https://video.jbc.ae/360View/JBC/',
  	jbcMapinfo: [{},{lat: 25.071153607884046, lng: 55.1435650840356, link:'https://www.google.com/maps/place/Jumeirah+Business+Center+1+-+Jumeirah+Lakes+Towers+-+Dubai+-+United+Arab+Emirates/@25.0709787,55.1413442,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f6cae62b2630d:0xc3b17478e61f959a!8m2!3d25.0709787!4d55.1435329'},{lat: 25.07796510775624, lng: 55.15122372451279, link:'https://www.google.com/maps/place/JBC+2/@25.0776736,55.1490994,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f6ca6d98b4c57:0x77dcac9ffb3bacab!8m2!3d25.0776736!4d55.1512881' },{lat: 25.078247443155927, lng: 55.153734921493445, link: 'https://www.google.com/maps/place/Jumeirah+Business+Center+3+-+Jumeirah+Lakes+Towers+-+Dubai/@25.0780701,55.1535874,19z/data=!4m5!3m4!1s0x3e5f6ca6c3b55fc1:0x3ddd810908adece5!8m2!3d25.078192!4d55.1537517?shorturl=1' },{lat: 25.06333855629546, lng: 55.13834443985605, link:'https://www.google.com/maps/place/JBC+4/@25.063149,55.1361772,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f6cb349dc3b7b:0x8166008ca537ba3e!8m2!3d25.0631442!4d55.1383659?shorturl=1' },{lat: 25.076804439034838, lng: 55.14973107803508, link:'https://www.google.com/maps/place/Jumeirah+Business+Center+5+-+Cluster+W+-+Jumeirah+Lakes+Towers+-+Dubai/@25.0781163,55.1488835,17z/data=!4m5!3m4!1s0x3e5f6ca63e6ad14f:0xcef35962ba187f83!8m2!3d25.0765664!4d55.1497847?shorturl=1' }],
  	mapZoom: 17,
	cookieDomain: 'http://localhost:4200/',
  	slider1Config: {
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: ".slider-nav-1",
		fade: true
	},
	thumb1Config: {
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: ".slider-for-1",
		focusOnSelect: true,
		infinite: true,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 1008,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 800,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			  }
			}

		  ]
	},
	slider2Config: {
		slidesToShow: 1,
		slidesToScroll: 1,
		asNavFor: ".slider-nav-2",
		fade: true
	},
	thumb2Config: {
		slidesToShow: 5,
		slidesToScroll: 1,
		asNavFor: ".slider-for-2",
		focusOnSelect: true,
		infinite: true,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 1008,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 800,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			  }
			}

		  ]
	},
	benefitSliderConfig: {
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		infinite: true,
    	cssEase: 'linear',
    	variableWidth: false,
		variableHeight: false,
		loop: true,
		arrows: true,
		responsive: [
			{
			  breakpoint: 1200,
			  settings: {
				slidesToShow: 3,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 1008,
			  settings: {
				slidesToShow: 2,
				slidesToScroll: 1
			  }
			},
			{
			  breakpoint: 800,
			  settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			  }
			}

		  ]
	},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
