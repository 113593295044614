import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { GoogleMapsModule } from '@angular/google-maps';
import { NotifierModule, NotifierOptions } from 'angular-notifier';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import { LightboxModule } from 'ngx-lightbox';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { HomeComponent } from './components/home/home.component';
import { AboutusComponent } from './components/aboutus/aboutus.component';
import { TowersComponent } from './components/towers/towers.component';
import { BuyComponent } from './components/buy/buy.component';
import { LeaseComponent } from './components/lease/lease.component';
import { MediaComponent } from './components/media/media.component';
import { ContactusComponent } from './components/contactus/contactus.component';
import { JbcComponent } from './components/jbc/jbc.component';
import { HeaderComponent } from './components/blocks/header/header.component';
import { FooterComponent } from './components/blocks/footer/footer.component';
import { MenuComponent } from './components/blocks/menu/menu.component';
import { TermsComponent } from './components/terms/terms.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { BrokerComponent } from './components/broker/broker.component';
import { BlogComponent } from './components/blog/blog.component';
import { environment } from "../environments/environment";

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "jbc.ae"
  },
  "position": "bottom",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "This website uses cookies to ensure you get the best experience on our website.",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "policy": "Cookie Policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutusComponent,
    TowersComponent,
    BuyComponent,
    LeaseComponent,
    MediaComponent,
    ContactusComponent,
    JbcComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    TermsComponent,
    PrivacyComponent,
    BrokerComponent,
    BlogComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ngx-cookieconsent-demo-id' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SlickCarouselModule,
    GoogleMapsModule,
    NgxSpinnerModule,
    LazyLoadImageModule,
    LightboxModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'right',
          distance: 12
        },
        vertical: {
          position: 'bottom',
          distance: 50,
          gap: 10
        }
      },
      behaviour: {
        autoHide: 5000,
        onClick: 'hide',
        onMouseover: 'pauseAutoHide',
        showDismissButton: true,
        stacking: 4
      },
      animations: {
        enabled: true,
        show: {
          preset: 'slide',
          speed: 300,
          easing: 'ease'
        },
        hide: {
          preset: 'fade',
          speed: 300,
          easing: 'ease',
          offset: 50
        },
        shift: {
          speed: 300,
          easing: 'ease'
        },
        overlap: 150
      }
    })
  ],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
