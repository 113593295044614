import { Component, OnInit } from '@angular/core';
import { WebService } from "../../service/web.service";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-broker',
  templateUrl: './broker.component.html',
  styleUrls: ['./broker.component.css']
})
export class BrokerComponent implements OnInit {
  enquireForm: FormGroup;
  isSubmitted: boolean = false;
  formData = {
    name: "",
    mobile: "",
    email:"",
    company_name:"",
    city:"",
    country:""
  };
  constructor(private webService: WebService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.enquireForm = this.formBuilder.group({
      name: ["", Validators.required],
      mobile: ["", Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      company_name: ["", Validators.required],
      city: ["", Validators.required],
      country: ["", Validators.required]
    });
    this.webService.hideLoader();
  }

  get f() { return this.enquireForm.controls; }

  inputChange(){
    this.isSubmitted = false;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.enquireForm.invalid) {
      return;
    }
    this.webService.showLoader();
    this.webService.brokerenquiry(this.formData).subscribe(
      data => {
        this.webService.hideLoader();
        this.webService.showNotification('success',data['message']);
        this.isSubmitted = false;
        this.formData = {
          name: "",
          mobile: "",
          email:"",
          company_name:"",
          city:"",
          country:""
        };
      },
      (err: HttpErrorResponse) => {
        this.webService.hideLoader();
        if (err.status == 401) {
          // /this.webService.showErrorAlert(err.error.message.message[0]);
        }else{
          this.webService.showNotification('error', 'Oops Some internal Server Error');
        }
      }
    );
  }

}
