import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { WebService } from "../../service/web.service";
import { GoogleMapsModule, MapInfoWindow, MapMarker, GoogleMap } from "@angular/google-maps";

@Component({
  selector: "app-contactus",
  templateUrl: "./contactus.component.html",
  styleUrls: ["./contactus.component.css"]
})
export class ContactusComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  zoom: number;
  options: any;
  center: any;
  markers: any;
  loading: boolean = true;
  pageContents: any;
  imgBaseUrl: string;
  activetab: number = 1;
  infoCoords: any = {link: ''};

  constructor(private webService: WebService) {}
  ngOnInit(): void {
    this.zoom = this.webService.zoom;
    this.options = this.webService.options;
    this.imgBaseUrl = this.webService.imgBaseUrl;
    this.getpageContent();
  }

  getpageContent() {
    this.webService.contactusContent().subscribe(
      resultData => {
        this.pageContents = resultData;
        this.changeTab(this.activetab);
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.webService.hideLoader();
        if (err.status == 401) {
          alert(err.error.message);
        }
      }
    );
  }

  changeTab(tab) {
    this.activetab = tab;
    this.center = this.webService.getMapInfo(this.activetab);
    this.markers = this.webService.getMapMarkers(this.activetab, this.center);
    this.loading = false;
    this.webService.hideLoader();
  }

  openInfoWindow(marker,index){
    this.infoCoords.link = marker._position.link;
    this.infoWindow.open(marker);
  }
}
