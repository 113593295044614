<!-- Banner -->
<section id="banner" class="major aboutus_banner" [defaultImage]="'assets/media/images/banner-dummy.jpg'"
   [lazyLoad]="imgBaseUrl + pageContents?.banner_img">
   <img [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.banner_img"
      class="onlymobile">
   <div class="inner">
   </div>
</section>
<!-- Main -->
<div id="main" class="about-content">
   <div class="bg_gray p-5 ">
      <div class="container ">
         <div class="inner text-center">
            <h4 class="theme_color mb-0 font-weight-normal arabic-center">
               {{pageContents?.activelanguage?.constant_values?.about_us_banner_title_1}}</h4>
            <h1 class="theme_color mb-0 font-weight-bold arabic-center">
               {{pageContents?.activelanguage?.constant_values?.about_us_banner_title_2}}</h1>
         </div>
      </div>
   </div>
   <!-- <div class="bg_white  p-5">
      <div class="container ">
         <div class="row">
            <div class="col-md-4 "> -->
               <!-- <img _ngcontent-nlj-c14="" class="img-fluid mt-1" [lazyLoad]="imgBaseUrl + pageContents?.president_img"> -->
               <!--  <div class="min_h_img unstretched" [lazyLoad]="imgBaseUrl + pageContents?.president_img"></div> -->
               <!-- <img class="w-100" [defaultImage]="'assets/media/images/president-dummy.jpg'"
                  [lazyLoad]="imgBaseUrl + pageContents?.president_img">
            </div>
            <div class="col-md-8 text-center arabic-center">
               <img class="img-fluid m-auto" src="assets/media/images/jbclogo.svg">
               <h3 class="theme_color text-center arabic-center">
                  {{pageContents?.activelanguage?.constant_values?.about_us_president_msg_title}}</h3>
               <p class="black_color mb-3 text-justify f14 pre-wrap"
                  [innerHtml]="pageContents?.activelanguage?.constant_values?.about_us_president_msg_content">
               </p>
               <p class="black_color text-left"><span
                     class="font-weight-bold">{{pageContents?.activelanguage?.constant_values?.about_us_president_name}}
                  </span><br /> {{pageContents?.activelanguage?.constant_values?.about_us_president_info}}
               </p>
            </div>
         </div>
      </div>
   </div> -->
   <div class="bg_white  p-5">
      <div class="container ">
         <div class="row">
            <div class="col-md-4 onlymobile">
               <!-- <div class="min_h_img unstretched"  [lazyLoad]="imgBaseUrl + pageContents?.coo_img"></div> -->
               <img class="w-100" [defaultImage]="'assets/media/images/naeem-dummy.jpg'"
                  [lazyLoad]="imgBaseUrl + pageContents?.coo_img">
            </div>
            <div class="col-md-8 text-center arabic-center flex_1 onlyweb">
               <img class="img-fluid m-auto" src="assets/media/images/jbclogo.svg">
               <h4 class="theme_color text-center arabic-center">
                  {{pageContents?.activelanguage?.constant_values?.about_us_cheif_operating_officer_name}}</h4>
               <p class="black_color text-center arabic-center">
                  {{pageContents?.activelanguage?.constant_values?.about_us_cheif_operating_officer_info}}
               </p>
               <p class="black_color mb-3 mt-3 text-justify f14 pre-wrap"
                  [innerHtml]="pageContents?.activelanguage?.constant_values?.about_us_cheif_operating_officer_content">
               </p>

            </div>
            <div class="col-md-8 text-center flex_1 onlymobile">
               <img class="img-fluid m-auto" src="assets/media/images/jbclogo.svg">
               <h4 class="theme_color text-center">
                  {{pageContents?.activelanguage?.constant_values?.about_us_cheif_operating_officer_name}}</h4>
               <p class="black_color text-center">
                  {{pageContents?.activelanguage?.constant_values?.about_us_cheif_operating_officer_info}}
               </p>
               <p class="black_color mb-3 mt-3 text-justify f14 pre-wrap">
                  {{pageContents?.activelanguage?.constant_values?.about_us_cheif_operating_officer_content}}
               </p>

            </div>
            <div class="col-md-4 onlyweb flex_1">
               <!-- <div class="min_h_img unstretched"  [lazyLoad]="imgBaseUrl + pageContents?.coo_img"></div> -->
               <img class="w-100" [lazyLoad]="imgBaseUrl + pageContents?.coo_img"
                  [defaultImage]="'assets/media/images/naeem-dummy.jpg'">
               <!-- <img _ngcontent-nlj-c14="" class="img-fluid mt-1" [lazyLoad]="imgBaseUrl + pageContents?.coo_img"> -->
            </div>
         </div>
      </div>
   </div>
   <div class="bg_white  p-5">
      <div class="container executive-area arabic-center">
         <img class="img-fluid m-auto d-block" src="assets/media/images/jbclogo.svg">
         <h2 class="theme_color mb-5 text-center arabic-center">
            {{pageContents?.activelanguage?.constant_values?.about_us_executive_team_lable}}</h2>
         <div class="row">
            <div class="col-md-15 col-lg-15 col-sm-15 col-xs-15 tower mt-3 text-center"
               *ngFor="let executive of pageContents?.executives; let i = index">
               <div class="mt-1 exec_user" [defaultImage]="'assets/media/images/executive-dummy.jpg'"
                  [lazyLoad]="imgBaseUrl + executive?.avatar"><img class="w-100 onlymobile"
                     [lazyLoad]="imgBaseUrl + executive?.avatar"></div>
               <div class="exec_info">
                  <h5 class="primary_color mb-0">{{executive.name}}</h5>
                  <p class="gray_color f12 border-bottom executive-role">{{executive.role}}</p>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="bg_white  p-5">
      <div class="container ">
         <div class="row align_vcenter">
            <div class="col-md-5  mtminus_left onlymobile">
               <div class="min_h_img">
                  <img class="w-100" [lazyLoad]="imgBaseUrl + pageContents?.who_we_are_img"
                     [defaultImage]="'assets/media/images/about-bottom-dummy-banner.jpg'">
               </div>

            </div>
            <div class="col-md-7 text-center ">
               <div class="p-3 box_dark">
                  <div class="border_light p-4 min_h300 align_center">
                     <div class="arabic-center">
                        <img class="img-fluid m-auto " src="assets/media/images/jbclogo.svg">
                        <h3 class="white_color text-center arabic-center">
                           {{pageContents?.activelanguage?.constant_values?.about_us_who_we_are_lable}}</h3>
                        <p class="white_color mb-3 text-justify f14 col-sm-12  pre-wrap pl-5 pr-5"
                           [innerHtml]="pageContents?.activelanguage?.constant_values?.about_us_who_we_are_content">
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-5  mtminus_left onlyweb ">
               <div class="min_h_img align_center">
                  <img class="w-100" [lazyLoad]="imgBaseUrl + pageContents?.who_we_are_img"
                     [defaultImage]="'assets/media/images/about-bottom-dummy-banner.jpg'">
               </div>

            </div>
         </div>
      </div>
   </div>
   <div class="bg_white  p-5 pb-0">
      <div class="container align_vcenter">
         <div class="row align_vcenter">
            <div class="col-md-5   zindex99 mtminus_right ">

               <div class="min_h_img align_center">
                  <img class="w-100" [lazyLoad]="imgBaseUrl + pageContents?.our_vision_img"
                     [defaultImage]="'assets/media/images/about-bottom-dummy-banner.jpg'">
               </div>
            </div>
            <div class="col-md-7 text-center mtplus_right">
               <div class="p-3 box_light">
                  <div class="border_dark p-4 min_h300 align_center">
                     <div class="arabic-center">
                        <img class="img-fluid m-auto" src="assets/media/images/jbclogo.svg">
                        <h3 class="theme_color text-center arabic-center">
                           {{pageContents?.activelanguage?.constant_values?.about_us_our_vision_lable}}</h3>
                        <p class="black_color  mb-3 text-justify f14 pl-5 pr-5 col-sm-12 mfull_0 pfull_0 pre-wrap"
                           [innerHtml]="pageContents?.activelanguage?.constant_values?.about_us_our_vision_content"></p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <div class="bg_white  p-5">
      <div class="container ">
         <div class="row align_vcenter">
            <div class="col-md-5 p-3  mtminus_left onlymobile">
               <div class="min_h_img align_center">
                  <img class="w-100" [lazyLoad]="imgBaseUrl + pageContents?.our_mission_img"
                     [defaultImage]="'assets/media/images/about-bottom-dummy-banner.jpg'">
               </div>
            </div>
            <div class="col-md-7 text-center">
               <div class="p-3 box_dark">
                  <div class="border_light p-4 min_h300 align_center">
                     <div class="arabic-center">
                        <img class="img-fluid m-auto" src="assets/media/images/jbclogo.svg">
                        <h3 class="theme_color text-center arabic-center white_color">
                           {{pageContents?.activelanguage?.constant_values?.about_us_our_mission_lable}}</h3>
                        <p class="white_color mb-5 text-justify pl-5 pr-5 f14 col-md-12 pre-wrap"
                           [innerHtml]="pageContents?.activelanguage?.constant_values?.about_us_our_mission_content">
                        </p>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col-md-5 p-3  mtminus_left onlyweb">
               <div class="min_h_img align_center">
                  <img class="w-100" [lazyLoad]="imgBaseUrl + pageContents?.our_mission_img"
                     [defaultImage]="'assets/media/images/about-bottom-dummy-banner.jpg'">
               </div>
            </div>
         </div>
      </div>
   </div>
</div>