<!-- Banner Start-->
<section id="banner" class="major homebanner player-wrapper">
    <video [loop]="true" [volume]="0.2" playsinline  [muted]="true" [src]="pageContents?.banner_video" id="homeBanner"
        poster="https://jbc-assets.s3.amazonaws.com/images/home-poster.jpg">
    </video>
    <div class="video_overlay" (click)="playPause()"></div>
    <div class="play_holder"><img class="playpause-btn" (click)="playPause()" src="assets/media/images/{{playStatus ? 'pause' : 'play'}}.svg"></div>
   
    <img class="mute-btn" src="assets/media/images/{{soundStatus ? 'un' : ''}}mute.svg" (click)="changeMute()">
</section>
<!-- Banner End-->

<!-- Main Start-->
<div id="main" class="jbc-home">

    <div class="bg_gray p-5">
        <div class="container">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 arabic-center">
                    {{pageContents?.activelanguage?.constant_values?.home_ambition_block_title}}</h1>
                <p class="theme_color">{{pageContents?.activelanguage?.constant_values?.home_ambition_block_content}}
                </p>
            </div>
        </div>
    </div>

    <div class="bg_white p-5">
        <div class="container">
            <h3 class="theme_color mb-5 text-center arabic-center">
                {{pageContents?.activelanguage?.constant_values?.home_towers_title}}</h3>
            <div class="row">
                <div class="col-md-15 col-lg-15 col-sm-15 col-xs-15 tower mt-5" [routerLink]="['/jbc/', 1, 1]">
                    <img [defaultImage]="'assets/media/images/towers-dummy.jpg'"
                        [lazyLoad]="imgBaseUrl + pageContents?.jbc_img_1" class="img-fluid">
                    <div class="proj_holder">
                        <div class="proj_name">JBC 1</div>
                    </div>
                </div>
                <div class="col-md-15 col-lg-15 col-sm-15 col-xs-15 tower mb-5" [routerLink]="['/jbc/', 2, 1]">
                    <img [defaultImage]="'assets/media/images/towers-dummy.jpg'"
                        [lazyLoad]="imgBaseUrl + pageContents?.jbc_img_2" class="img-fluid">
                    <div class="proj_holder">
                        <div class="proj_name">JBC 2</div>
                    </div>
                </div>
                <div class="col-md-15 col-lg-15 col-sm-15 col-xs-15 tower mt-5" [routerLink]="['/jbc/', 3, 1]">
                    <img [defaultImage]="'assets/media/images/towers-dummy.jpg'"
                        [lazyLoad]="imgBaseUrl + pageContents?.jbc_img_3" class="img-fluid">
                    <div class="proj_holder">
                        <div class="proj_name">JBC 3</div>
                    </div>
                </div>

                <div class="col-md-15 col-lg-15 col-sm-15 col-xs-15 tower mb-5" [routerLink]="['/jbc/', 4, 1]">
                    <img [defaultImage]="'assets/media/images/towers-dummy.jpg'"
                        [lazyLoad]="imgBaseUrl + pageContents?.jbc_img_4" class="img-fluid">
                    <div class="proj_holder">
                        <div class="proj_name">JBC 4</div>
                    </div>
                </div>
                <div class="col-md-15 col-lg-15 col-sm-15 col-xs-15 tower mt-5" [routerLink]="['/jbc/', 5, 1]">
                    <img [defaultImage]="'assets/media/images/towers-dummy.jpg'"
                        [lazyLoad]="imgBaseUrl + pageContents?.jbc_img_5" class="img-fluid">
                    <div class="proj_holder">
                        <div class="proj_name">JBC 5</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray  p-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 align_bottom  d-sm-none d-md-none d-lg-block onlyweb">
                    <img class="w-100 mbm-5" [lazyLoad]="imgBaseUrl + pageContents?.why_us_img"
                        [defaultImage]="'assets/media/images/why-us-dummy.jpg'">
                </div>
                <div class="col-lg-6 text-center arabic-center ">
                    <img class="img-fluid m-auto" src="assets/media/images/jbclogo.svg">

                    <h3 class="theme_color text-center arabic-center">
                        {{pageContents?.activelanguage?.constant_values?.home_why_us_title}}</h3>
                    <img class="w-100 mt-3 mb-3 d-sm-block d-md-block d-lg-none"
                        [lazyLoad]="imgBaseUrl + pageContents?.why_us_img"
                        [defaultImage]="'assets/media/images/why-us-dummy.jpg'">
                    <p class="primary_color mb-2 text-justify pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.home_why_us_content"></p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Main End-->
