import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, NavigationEnd } from '@angular/router';
import { WebService } from "../../service/web.service";
import { UrlService } from '../../service/url.service';
import { filter, pairwise } from 'rxjs/operators';
import * as $ from "jquery";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  loading = true;
  pageContents: any;
  imgBaseUrl: string;
  soundStatus: boolean = true;
  playStatus: boolean = true;
  previosLink: string = '';
  FromHome: boolean = true;

  constructor(private webService: WebService, public router: Router, private urlService: UrlService) { }

  ngOnInit(): void {
    this.imgBaseUrl = this.webService.imgBaseUrl;
    this.getpageContent();
  }

  getpageContent() {
    this.webService.homeContent().subscribe(
      resultData => {
        this.loading = false;
        this.pageContents = resultData;
        if (this.webService.previousUrl != '' && this.webService.previousUrl != '/') {
          this.soundStatus = false;
          this.playStatus = false;
          $("video").prop('muted', false);
        }else{
          this.soundStatus = true;
          this.playStatus = true;
          $("video").attr("autoplay","autoplay");
          $("video").attr("mute","");
        }
        this.webService.hideLoader();
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.webService.hideLoader();
        if (err.status == 401) {
          alert(err.error.message);
        }
      }
    );
  }

  changeMute() {
    if ($("video").prop('muted')) {
      this.soundStatus = false;
      $("video").prop('muted', false);
    }
    else {
      this.soundStatus = true;
      $("video").prop('muted', true);
    }
  }

  playPause() {
    if (this.playStatus) {
      this.playStatus = false;
      $('video').trigger('pause');
    }
    else {
      this.playStatus = true;
      $('video').trigger('play');
    }
  }
}
