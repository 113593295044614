
<div id="main" class="jbc-home">
    <div class="container mt-5 text-center ">
        <img [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + blog?.image" class="img-fluid mt-5">
        
    </div>
    <div class="bg_white p-4 text-center">
        <div class="container">

            <h1 class="theme_color mt-3 mb-4">
                {{blog?.title}}
            </h1>
            <p class="mb-4 theme_color pre-wrap" [innerHtml]="blog?.description">
            </p>
        </div>
    </div>
</div>