<div id="main">
    <div class="bg_white p-4 mt-5 ">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color">Privacy Policy</h1>
            </div>
        </div>
    </div>
    <div class="bg_white mb-5">
        <div class="container ">
            <div class="row">
                <div class="col-sm-12 ">
                    <p class="black_color mb-3 text-justify f14">
                    Please read these Terms and Conditions carefully. These are general terms and conditions of JBC Properties (“Company”) governing your access and use of the “website”. If you do not agree with these terms and conditions, please do not use the website. By continuing to use the website, contents and/or any of the services shown on the site, you agree to be bound by these Terms and Conditions.
                    </p>
                    <h5 class="black_color mb-3">Acceptance</h5>
                    <p class="black_color mb-3 text-justify f14">
                        You undertake that your use of the website [which includes all contents on the screen, web links, emails and other services and information along with the subsequent changes in due course (“contents”)] means you are fully agreed on the terms and conditions. Your eligibility of the product and services offered on the website is subject to JBC Properties determination and acceptance. Also you undertake that your use of the website shall not:  
                    </p>
                    <div class="black_color mb-3 f14">
                        <ul class="t_c">
                        <li>Violate the respective applicable laws.</li>
                        <li>Harm the public image of the website and will be used only for all defined, permitted and productive means.</li>
                        <li>Act any fraudulent business activity. Use of the website and any consequential business decision will be at the user´s own risk.</li>
                        </ul>
                    </div>
                    <h5 class="black_color mb-3">Copyright © JBC Properties, All Rights Reserved</h5>
                    <p class="black_color mb-3 text-justify f14">
                        Copyright in the pages and in the screens displaying the pages, and in the information and material therein and in their arrangement, is owned by JBC Properties unless otherwise indicated. Re-use of any content is strictly prohibited unless otherwise stated.
                    </p>
                    <h5 class="black_color mb-3">Trademarks</h5>
                    <p class="black_color mb-3 text-justify f14">
                        JBC Properties, its subsidiaries, affiliates, contractors and/or participating corporations are the owners of the trade and service marks appearing on this website and all rights are reserved in respect of such trade and service marks.
                    </p>
                    <h5 class="black_color mb-3">No Warranty</h5>
                    <p class="black_color mb-3 text-justify f14">
                        The company does not warrant the accuracy, correctness, completeness and acceptance of the contents and/or any other information and/or materials available on the website. No warranties of any kind whether express, implied or by laws, including but not limited to the warranties of non-infringement of third party rights, security, fitness, quality, compatibility, accuracy and safety.    
                    </p>
                    <h5 class="black_color mb-3">Limitation of Liability</h5>
                    <p class="black_color mb-3 text-justify f14">
                        Under no circumstance JBC Properties be liable for any feature, function (programming errors, other errors, linking, rectification of the errors, updation, omissions), and safety (including loss/ damage to any personal data/information, or any loss whether foreseen, foreseeable, known or otherwise) mechanism built in the website. JBC Properties or any of its affiliate is not liable for any consequential, incidental damages whether direct or indirect arising out of the use of the website. Contents of the website have not been investigated, verified and/or not continuously monitored. Company reserves all rights to disclose information as it deems necessary to satisfy any applicable law, or refuse to disclose, or edit, or to remove any content or information or materials, in whole or in part, at company´s sole discretion.   
                    </p>
                    <h5 class="black_color mb-3">Submission</h5>
                    <p class="black_color mb-3 text-justify f14">
                        All information submitted to JBC Properties via this site shall be deemed and remain the property of JBC Properties who shall be free to use, for any purpose, any ideas, concepts, know-how or techniques contained in information a visitor to this site provides JBC Properties through this site. JBC Properties shall not be subject to any obligations of confidentiality or privacy regarding submitted information except as agreed by the JBC Properties or as otherwise specifically agreed or required by law.    
                    </p>
                    <h5 class="black_color mb-3">Variations</h5>
                    <p class="black_color mb-3 text-justify f14">
                        These terms and conditions are subject to change at any time without notice and even after any such change will not change the obligation of the user.    
                    </p>
                    <h5 class="black_color mb-3">Governing Laws and Jurisdiction</h5>
                    <p class="black_color mb-3 text-justify f14">
                        Your use of the company website, its content, services and/or any information/material posted or available on website shall be governed by the law of the United Arab Emirates and you agree to submit to the exclusive jurisdiction of the courts of the United Arab Emirates.   
                    </p>
                    <h5 class="black_color mb-3">Disclaimer</h5>
                    <p class="black_color mb-3 text-justify f14">
                        No representation or warranty of any kind whatsoever express or implied for the accuracy, correctness, completeness, fitness to the purpose or non-infringement of any content or any other information or material posted or available on the website. Any tangible or intangible harm/damage or any consequential or incidental damages thereof of any kind to the user, by accessing the website and/or any breach of the undertaking, is at User´s own risk.    
                    </p>
                </div>
                
            </div>
            
        </div>
    </div>
</div>