<footer id="footer" class="bg_gray">
    <div class="container text-center">
        <ul class="icons">
            <li><a href="#" class="icon brands alt fa-twitter"><span class="label">Twitter</span></a></li>
            <li><a href="#" class="icon brands alt fa-facebook-f"><span class="label">Facebook</span></a></li>
            <li><a href="#" class="icon brands alt fa-instagram"><span class="label">Instagram</span></a></li>
            <li><a href="#" class="icon brands alt fa-github"><span class="label">GitHub</span></a></li>
            <li><a href="#" class="icon brands alt fa-linkedin-in"><span class="label">LinkedIn</span></a></li>
        </ul>
        <div class="row">
            <div class="col-sm-3 text-left primary_color foot_links">
                <h4 class="mb-2 primary_color">QUICK LINKS</h4>
                <ul>
                    <li><a [routerLink]="['']">Home</a></li>
                    <li><a [routerLink]="['/aboutus']">About Us</a></li>
                    <li><a [routerLink]="['/towers']">Our Towers</a></li>
                    <li><a [routerLink]="['/buy']">Buy</a></li>
                    <li><a [routerLink]="['/lease']">Lease</a></li>
                    <li><a [routerLink]="['/media']">Media</a></li>
                    <li><a [routerLink]="['/contactus']">Contact Us</a></li>
                </ul>
            </div>
            <div class="col-sm-3 text-left primary_color foot_links">
                <h4 class="mb-2 primary_color">CONTACT US</h4>
                <ul>
                    <li><a>Tel: +971 4 2444 450</a></li>
                    <li><a>Email: info@jbc.ae</a></li>
                </ul>
            </div>
            <div class="col-sm-3 text-left primary_color foot_links">
                <h4 class="mb-2 primary_color">ADDRESS</h4>
                <ul>
                    <li>Jumeirah Business Centre 1,</li>
                    <li>Level 43, Cluster G,</li>
                    <li>Jumeirah Lakes Towers,</li>
                    <li>P.O. Box 33770, Dubai,</li>
                    <li>United Arab Emirates</li>
                </ul>
            </div>
            <div class="col-sm-3 text-left primary_color foot_links">
                <h4 class="mb-2 primary_color">SUBSCRIBE</h4>
                <div class="row">
                    <div class="col-7 pdn">
                        <input type="text" name="email" id="email" class="bdr_primary">
                    </div>
                    <div class="col-5">
                        <input type="submit" name="SUBMIT" id="SUBMIT" value="subscribe" class="sub_pd">
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="p-3 primary_bg_color">
    <div class="container ">
        <div class="row">
            <div class="white_color col-sm-6 text-left">© 2019 JBC Properties. All Rights Reserved.</div>
            <div class="white_color col-sm-6 text-right">Terms & Conditions | Privacy Policy</div>
        </div>
    </div>
</div>