<!-- Banner -->
<section id="banner" class="major media_banner" [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.banner_img">
    <img [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad] [src]="imgBaseUrl + pageContents?.banner_img" class="onlymobile">
    <div class="inner">
    </div>
</section>
<!-- Main -->
<div id="main" class="media-content">
    <div class="bg_white p-3 ">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.media_lable}}</h1>
            </div>
        </div>
    </div>

    <div class="bg_gray p-3">
        <div class="container  pl-0 pr-0">
            <div class="inner col-md-12 text-center pl-0 pr-0">
                <h1 class="theme_color mb-2 arabic-center">{{pageContents?.activelanguage?.constant_values?.view_360_button_lable}}</h1>
                <div class="col-md-12 p-0" *ngIf="!loading">
                    <ul class="nav nav-pills theme_nav nav-fill">
                        <li class="nav-item p-0" *ngFor="let jbc of jbcs; let i = index" (click)="change360Tab(jbc)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': active360  == jbc }">JBC {{jbc}}</a>
                        </li>
                    </ul>
                    <iframe [src]="View360Url" width="100%" height="670px"></iframe>
                </div>
            </div>
        </div>
    </div>

    <div class="bg_white  p-3">
        <div class="container ">
            <div class="row">
                <div class="col-sm-8 mt-0 zindex99 onlymobile">
                    <div class="p-3">
                        <video width="100%" poster="https://jbc-assets.s3.amazonaws.com/images/poster.jpg" controls [src]="pageContents?.video_url"></video>
                    </div>
                </div>
                <div class="col-sm-4 text-center p-2 theme_color dflex_div dlfex_column f16">
                    <h1 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.watch_video_lable}}</h1>
                    {{pageContents?.activelanguage?.constant_values?.media_video_block_content}}
                </div>

                <div class="col-sm-8 mt-0 zindex99 onlyweb pr-0">
                    <div class="p-0 mt-1 mb-1">
                        <video width="100%" poster="https://jbc-assets.s3.amazonaws.com/images/poster.jpg" controls [src]="pageContents?.video_url"></video>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- {{pageContents?.gallery | json}} -->
    <div class="bg_gray p-5">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 arabic-center">IMAGE GALLERY</h1>
                <div class="col-md-12 p-0" *ngIf="!loading">
                    <ngx-slick-carousel class="carousel slider-for-2 mb-0" #slickModal="slick-carousel" [config]="slider2Config">
                        <div ngxSlickItem *ngFor="let slide of pageContents?.gallery; let i = index" class="slide">
                            <img [src]="imgBaseUrl + slide" alt="" width="100%" (click)="open(i)">
                        </div>
                    </ngx-slick-carousel>

                    <ngx-slick-carousel class="carousel slider-nav-2" #slickModal="slick-carousel" [config]="thumb2Config">
                        <div ngxSlickItem *ngFor="let slide of pageContents?.gallery" class="slide">
                            <img [src]="imgBaseUrl + slide" alt="" width="100%">
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>

    <div class="bg_white p-5">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 arabic-center">{{pageContents?.activelanguage?.constant_values?.latest_news_lable}}</h1>
                <div class="row text-left blog-area">
                    <div class="col-md-6 mb-5 pb-5 m-auto media_gray border_big_white" *ngFor="let news of pageContents?.news">
                        <img class="img-fluid cust_height" [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + news.image">
                        <!-- <div class="news_date theme_color">{{news.created_at | date: 'dd MMMM yyyy'}}</div> -->
                        <h5 class="theme_color f18 news_title m-2">{{news.title}}</h5>
                        <p class="black_color f13 news_description m-2 pre-wrap text-justify">{{news.description}}</p>
                        <div class="text-center">
                            <a [routerLink]="['/blog/', news.id]" class="button text-center  small mr-3 mt-3">{{pageContents?.activelanguage?.constant_values?.read_more_lable}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg_gray p-5">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 border_bottom">{{pageContents?.activelanguage?.constant_values?.locations_lable}}</h1>
                <div class="">
                    <ul class="nav nav-pills mb-3  theme_nav nav-fill">
                        <li class="nav-item p-0" (click)="changeTab(1)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 1 }">JBC1</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(2)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 2 }">JBC2</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(3)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 3 }">JBC3</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(4)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 4 }">JBC4</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(5)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 5 }">JBC5</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <div>
                                <google-map height="450" width="100%" [zoom]="zoom" [center]="center" [options]="options">
                                    <map-marker *ngFor="let marker of markers" #somemarker="mapMarker" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfoWindow(somemarker,marker.title)">
                                    </map-marker>
                                    <map-info-window>
                                        <h5 class="title full-width text-dark"> Jumeirah Business Centre - {{activetab}}
                                        </h5>
                                        <div class="address">
                                            <div class="address-line full-width text-muted">Jumeirah Lakes Towers -
                                                Dubai</div>
                                            <div class="address-line full-width text-muted">United Arab Emirates</div>
                                        </div>
                                        <a class="address-line full-width text-primary" target="_blank"
                                            href="{{infoCoords.link}}">View on Google Maps</a>
                                    </map-info-window>
                                </google-map>
                            </div>
                            <div class="theme_bg_color pre-wrap arabic-center">
                                {{ pageContents?.activelanguage?.constant_values['towers_jbc_address_' + activetab] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>