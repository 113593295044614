import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { WebService } from "../../service/web.service";

@Component({
  selector: "app-aboutus",
  templateUrl: "./aboutus.component.html",
  styleUrls: ["./aboutus.component.css"]
})
export class AboutusComponent implements OnInit {
  loading: boolean = true;
  pageContents: any;
  imgBaseUrl: string;

  constructor(private webService: WebService) {}
  ngOnInit(): void {
    this.imgBaseUrl = this.webService.imgBaseUrl;
    this.getpageContent();
  }

  getpageContent() {
    this.webService.aboutusContent().subscribe(
      resultData => {
        this.pageContents = resultData;
        this.webService.hideLoader();
        this.loading = false;
      },
      (err: HttpErrorResponse) => {
        this.webService.hideLoader();
        this.loading = false;
        if (err.status == 401) {
          alert(err.error.message);
        }
      }
    );
  }
}
