<!-- Banner -->
<section id="banner" class="major buy_banner" [defaultImage]="'assets/media/images/banner-dummy.jpg'"
    [lazyLoad]="imgBaseUrl + pageContents?.banner_img">
    <img [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.banner_img"
        class="onlymobile">
    <div class="inner">

    </div>
</section>
<!-- Main -->
<div id="main" class="buy-content">
    <div class="bg_gray p-5 ">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.buy_title_lable}}</h1>
            </div>
        </div>
    </div>

    <div class="bg_white  p-5" *ngIf="pageContents?.ad_banner_status">
        <div class="container ">
            <div class="row">
                <div class="col-sm-12">
                    <a><img class="img-fluid mt-1" [defaultImage]="'assets/media/images/banner-dummy.jpg'"
                            [lazyLoad]="imgBaseUrl + pageContents?.ad_banner_img"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="bg_white  p-5">
        <div class="container ">
            <div class="row align_vcenter">
                <div class="col-md-5  zindex99 mtminus_right">

                    <div class="min_h_img ">
                        <img class="w-100" [defaultImage]="'assets/media/images/buy-or-lease-tower-dummy.jpg'"
                            [lazyLoad]="imgBaseUrl + pageContents?.jbc4_block_img">
                    </div>
                </div>

                <div class="col-md-7 text-center  mtplus_right min_h300">
                    <div class="p-3 box_light">
                        <div class="box_white min_h300 p-5 pfull_0">
                            <h3 class="theme_color text-center arabic-center">
                                {{pageContents?.activelanguage?.constant_values?.buy_jbc4_block_title}}</h3>

                            <div class="row col-md-12 text-center max-75 mfull_0">

                                <div class="col-md-4  p-2 ">
                                    <div class="border_theme border_w_2">
                                        <img class="img-fluid p-3"
                                            src="https://jbc-assets.s3.amazonaws.com/images/floors.png">
                                        <p class="theme_color m-0 f12 font-weight-bold">
                                            {{pageContents?.activelanguage?.constant_values?.towers_jbc4_floors}}</p>
                                        <p class="theme_color m-0 f12">
                                            {{pageContents?.activelanguage?.constant_values?.towers_floor_lable}}</p>
                                    </div>
                                </div>
                                <div class="col-md-4  p-2 ">
                                    <div class="border_theme border_w_2">
                                        <img class="img-fluid p-3"
                                            src="https://jbc-assets.s3.amazonaws.com/images/office_units.png">
                                        <p class="theme_color m-0 f12 font-weight-bold">
                                            {{pageContents?.activelanguage?.constant_values?.towers_jbc4_office_units}}
                                            {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                        <p class="theme_color m-0 f12">
                                            {{pageContents?.activelanguage?.constant_values?.towers_office_units_lable}}
                                        </p>
                                    </div>
                                </div>
                                <div class="col-md-4  p-2 ">
                                    <div class="border_theme border_w_2">
                                        <img class="img-fluid p-3"
                                            src="https://jbc-assets.s3.amazonaws.com/images/retail_area.png">
                                        <p class="theme_color m-0 f12 font-weight-bold">
                                            {{pageContents?.activelanguage?.constant_values?.towers_jbc4_retail_area}}
                                            {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                        <p class="theme_color m-0 f12">
                                            {{pageContents?.activelanguage?.constant_values?.towers_retail_area_lable}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <p class="black_color new_padder text-justify mt-3 mb-3  f13 col-sm-12 mfull_0">
                                {{pageContents?.activelanguage?.constant_values?.buy_jbc4_block_content}}</p>

                            <div class="row col-md-12 max-90 text-center new_padder mfull_0">
                                <a [routerLink]="['/jbc/', 4, 0]"
                                    class="button medium mr-5 mt-3  mx-auto">{{pageContents?.activelanguage?.constant_values?.view_360_button_lable}}</a>
                                <a [routerLink]="['/jbc/', 4, 1]"
                                    class="button medium mr-5 mt-3  mx-auto">{{pageContents?.activelanguage?.constant_values?.explore_button_lable}}</a>
                                <a [routerLink]="['/jbc/', 4, 2]"
                                    class="button medium mr-5 mt-3 mb-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.enquire_button_lable}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg_gray p-5 ">
        <div class="container ">
            <div class="inner text-center arabic-center">
                <h1 class="theme_color mb-2 arabic-center">{{pageContents?.activelanguage?.constant_values?.buy_benefits_lable}}</h1>
                <div class="box_light pfull_0">
                    <div class="col-md-12 p-0 mb-4" *ngIf="!loading">
                        <ngx-slick-carousel class="carousel slider-nav-2 benefit-slider" #slickModal="slick-carousel"
                            [config]="benefitSliderConfig">
                            <div ngxSlickItem *ngFor="let slide of benefits; let i = index" class="slide">
                                <h4>{{slide}}</h4>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg_white p-4">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mt-5 mb-3 arabic-center">
                    {{pageContents?.activelanguage?.constant_values?.buy_its_your_location_lable}}</h1>
            </div>
        </div>
    </div>

    <div id="your-location">
        <div class="">
            <img [defaultImage]="'assets/media/images/location-dummy.jpg'"
                [lazyLoad]="'https://jbc-assets.s3.amazonaws.com/images/Its-your-location.jpg'" class="img-fluid">
        </div>
    </div>

    <div class="bg_white p-5">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 arabic-center">{{pageContents?.activelanguage?.constant_values?.buy_aminities_label}}</h1>
                <div class="borderdiv mb-2"></div>
                <div class="box_white row">
                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/golf course.png">
                        <h5 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.golf_course_lable}}
                        </h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/metro station.png">
                        <h5 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.metro_station_lable}}
                        </h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/banks and ATM.png">
                        <h5 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.banks_and_atm_lable}}
                        </h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/hotels.png">
                        <h5 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.hotels_lable}}</h5>
                    </div>


                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/cafe & restaurants.png">
                        <h5 class="theme_color arabic-center">
                            {{pageContents?.activelanguage?.constant_values?.cafe_restaurants_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/retail & convenience stores.png">
                        <h5 class="theme_color arabic-center">
                            {{pageContents?.activelanguage?.constant_values?.retail_convenience_stores_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/clinics & pharmacy.png">
                        <h5 class="theme_color arabic-center">
                            {{pageContents?.activelanguage?.constant_values?.clinics_pharmacy_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/fitness centre.png">
                        <h5 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.fitness_centre_lable}}
                        </h5>
                    </div>


                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/education & child care.png">
                        <h5 class="theme_color arabic-center">
                            {{pageContents?.activelanguage?.constant_values?.education_child_care_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/lakes & park.png">
                        <h5 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.lakes_park_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/spa & beauty saloon.png">
                        <h5 class="theme_color arabic-center">
                            {{pageContents?.activelanguage?.constant_values?.spa_beauty_saloon_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2"
                            src="https://jbc-assets.s3.amazonaws.com/images/amenities/dry cleaning.png">
                        <h5 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.dry_cleaning_lable}}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg_gray p-5">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 border_bottom arabic-center">
                    {{pageContents?.activelanguage?.constant_values?.locations_lable}}</h1>
                <div class="">
                    <ul class="nav nav-pills mb-3  theme_nav nav-fill">
                        <li class="nav-item p-0" (click)="changeTab(1)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 1 }">JBC1</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(2)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 2 }">JBC2</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(3)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 3 }">JBC3</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(4)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 4 }">JBC4</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(5)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 5 }">JBC5</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <div>
                                <google-map height="450" width="100%" [zoom]="zoom" [center]="center"
                                    [options]="options">
                                    <map-marker #somemarker="mapMarker" *ngFor="let marker of markers; let i = index"
                                        [position]="marker.position" [label]="marker.label" [title]="marker.title"
                                        [options]="marker.options" (mapClick)="openInfoWindow(somemarker,marker.title)">
                                    </map-marker>
                                    <map-info-window>
                                        <h5 class="title full-width text-dark"> Jumeirah Business Centre - {{activetab}}
                                        </h5>
                                        <div class="address">
                                            <div class="address-line full-width text-muted">Jumeirah Lakes Towers -
                                                Dubai</div>
                                            <div class="address-line full-width text-muted">United Arab Emirates</div>
                                        </div>
                                        <a class="address-line full-width text-primary" target="_blank"
                                            href="{{infoCoords.link}}">View on Google Maps</a>
                                    </map-info-window>
                                </google-map>
                            </div>
                            <div class="theme_bg_color pre-wrap arabic-center">
                                {{ pageContents?.activelanguage?.constant_values['towers_jbc_address_' + activetab] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>