import { Component, OnInit } from '@angular/core';
import { WebService } from "../../service/web.service";

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor(private webService: WebService) { }

  ngOnInit(): void {
    this.webService.hideLoader();
  }

}
