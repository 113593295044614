<nav id="menu" class="p-0">
    <div class="inner">
        <div class="onlyweb row">
            <div class="col-md-2 p-0 menudiv">
                <a [routerLink]="['/aboutus']" class="border-0 menuanchor">ABOUT US</a>
                <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/aboutus.png">
            </div>
            <div class="col-md-2 p-0 menudiv">
                <a [routerLink]="['/towers']" class="border-0 menuanchor">OUR TOWERS</a>
                <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/towers.png">
            </div>
            <div class="col-md-2 p-0 menudiv">
                <a [routerLink]="['/buy']" class="border-0 menuanchor">BUY</a>
                <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/buy.png">
            </div>
            <div class="col-md-2 p-0 menudiv">
                <a [routerLink]="['/lease']" class="border-0 menuanchor">LEASE</a>
                <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/lease.png">
            </div>
            <div class="col-md-2 p-0 menudiv">
                <a [routerLink]="['/media']" class="border-0 menuanchor">MEDIA</a>
                <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/media.png">
            </div>
            <div class="col-md-2 p-0 menudiv">
                <a [routerLink]="['/contactus']" class="border-0 menuanchor">CONTACT US</a>
                <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/contactus.png">
            </div>
        </div>
        <ul class="links onlymobile">
            <li><a [routerLink]="['']">Home</a></li>
            <li><a [routerLink]="['/aboutus']">About Us</a></li>
            <li><a [routerLink]="['/towers']">Our Towers</a></li>
            <li><a [routerLink]="['/buy']">Buy</a></li>
            <li><a [routerLink]="['/lease']">Lease</a></li>
            <li><a [routerLink]="['/media']">Media</a></li>
            <li><a [routerLink]="['/contactus']">Contact Us</a></li>
        </ul>
    </div>
    <a class="close" (click)="closeMenu()">Close</a>
</nav>