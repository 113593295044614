<div id="main">
    <div class="bg_white p-4 mt-5 ">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color">{{pageContents?.activelanguage?.constant_values?.terms_page_title_label}}</h1>
            </div>
        </div>
    </div>
    <div class="bg_white mb-5">
        <div class="container ">
            <div class="row">
                <div class="col-sm-12 ">
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_block1_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_acceptance_label}}</h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_acceptance_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_copyright_label}}</h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_copyright_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_trademarks_label}}</h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_trademarks_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_no_warranty_label}}</h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_no_warranty_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_limitation_of_liability_label}}</h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_limitation_of_liability_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_submission_label}}</h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_submission_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_variations_label}}</h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_variations_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_governing_laws_and_jurisdiction_label}}
                    </h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_governing_laws_and_jurisdiction_content">
                    </p>
                    <h5 class="black_color mb-3">
                        {{pageContents?.activelanguage?.constant_values?.terms_page_disclaimer_label}}</h5>
                    <p class="black_color mb-3 text-justify f14 pre-wrap"
                        [innerHtml]="pageContents?.activelanguage?.constant_values?.terms_page_disclaimer_content"></p>
                </div>
            </div>
        </div>
    </div>
</div>