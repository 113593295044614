<!-- Banner -->
<section id="banner" class="major tower_banner" [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.banner_img">
    <img  [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.banner_img" class="onlymobile">
    <div class="inner">
    </div>
</section>
<!-- Main -->
<div class="towers-content" id="main">
    <div class="bg_gray p-5 ">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.towers_title_text}}</h1>
            </div>
        </div>
    </div>
    <div class="bg_white p-5">
        <div class="container ">
            <div class="row">
                <div class="col-md-12">
                    <p class="theme_color m-0 f18 text-center pre-wrap">{{pageContents?.activelanguage?.constant_values?.towers_block_top_content}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray p-5">
        <div class="container ">
            <div class="row align_vcenter">
                <div class="col-md-5 zindex99 mtminus_left onlymobile">
                    <div class="min_h_img min_h align_center"><img class="w-100" [defaultImage]="'assets/media/images/our-towers-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.jbc_1_block_img"></div>

                    <!-- <img class="img-fluid " src="https://jbc-assets.s3.amazonaws.com/images/JBC-1.png"> -->
                </div>
                <div class="col-md-7 text-center  min_h400">
                    <div class="border_big_white min_h400 p-4 pfull_0 arabic-center">
                        <img class="img-fluid m-auto" src="assets/media/images/jbclogo.svg">
                        <h3 class="theme_color text-center arabic-center">JBC-1</h3>
                        <div class="row col-md-12 text-center max-75 mfull_0">
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/floors.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc1_floors}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_floor_lable}}</p>
                                </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/office_units.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc1_office_units}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_office_units_lable}}</p>
                                </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/retail_area.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc1_retail_area}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_retail_area_lable}}</p>
                                </div>
                            </div>
                        </div>
                        <p class="black_color  mt-3 mb-3 text-justify f13 col-md-12 mfull_0 pl-5 pr-5">{{pageContents?.activelanguage?.constant_values?.towers_jbc1_content}}</p>
                        <div class="row col-md-12 max-90 text-center new_padder mfull_0">
                            <a [routerLink]="['/jbc/', 1, 0]" class="button medium mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.view_360_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 1, 1]" class="button medium mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.explore_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 1, 2]" class="button medium mr-5 mt-3 mx-auto mb-3">{{pageContents?.activelanguage?.constant_values?.enquire_button_lable}}</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 zindex99 mtminus_left onlyweb">
                    
                    <div class="min_h_img min_h align_center untouch"><img class="w-100" [defaultImage]="'assets/media/images/our-towers-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.jbc_1_block_img"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray ">
        <div class="container ">
            <div class="row align_vcenter">
                <div class="col-md-5  zindex99 mtminus_right">
                     <div class="min_h_img min_h align_center" >
                         <img class="w-100" [defaultImage]="'assets/media/images/our-towers-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.jbc_2_block_img">
                     </div>


                </div>
                <div class="col-md-7 text-center  mtplus_right min_h400">
                    <div class="border_big_white min_h400 p-4 pfull_0 arabic-center">
                        <img class="img-fluid m-auto " src="assets/media/images/jbclogo.svg">
                        <h3 class="theme_color text-center arabic-center">JBC-2</h3>
                        <div class="row col-md-12 text-center max-75 mfull_0">
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/floors.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc2_floors}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_floor_lable}}</p>
                                </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/office_units.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc2_office_units}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_office_units_lable}}</p>
                                </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/retail_area.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc2_retail_area}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_retail_area_lable}}</p>
                                </div>
                            </div>
                        </div>
                        <p class="black_color new_padder mt-3 mb-3 text-justify f13 col-md-12 mfull_0">{{pageContents?.activelanguage?.constant_values?.towers_jbc2_content}}</p>
                        <div class="row col-md-12 max-90 text-center new_padder mfull_0">
                            <a [routerLink]="['/jbc/', 2, 0]" class="button medium mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.view_360_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 2, 1]" class="button medium ml-5 mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.explore_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 2, 2]" class="button medium mr-5 mt-3 mx-auto mb-3">{{pageContents?.activelanguage?.constant_values?.enquire_button_lable}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray p-5">
        <div class="container ">
            <div class="row align_vcenter">
                <div class="col-md-5  mtminus_left zindex99 onlymobile"><!-- 
                    <img class="img-fluid " src="https://jbc-assets.s3.amazonaws.com/images/JBC-3.png"> -->
                     <div class="min_h_img min_h align_center" >
                         <img class="w-100" [defaultImage]="'assets/media/images/our-towers-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.jbc_3_block_img">
                     </div>
                </div>
                <div class="col-md-7 text-center   min_h400">
                    <div class="border_big_white min_h400 p-4 pfull_0 arabic-center">
                        <img class="img-fluid m-auto " src="assets/media/images/jbclogo.svg">
                        <h3 class="theme_color text-center arabic-center">JBC-3</h3>
                        <div class="row col-md-12 text-center max-75 mfull_0">
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/floors.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc3_floors}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_floor_lable}}</p>
                                </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/office_units.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc3_office_units}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_office_units_lable}}</p>
                                </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/retail_area.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc3_retail_area}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_retail_area_lable}}</p>
                                </div>
                            </div>
                        </div>
                        <p class="black_color  mt-3 mb-3 text-justify new_padder f13 col-md-12 mfull_0">{{pageContents?.activelanguage?.constant_values?.towers_jbc3_content}}</p>
                        <div class="row col-md-12 max-90 text-center new_padder mfull_0">
                            <a [routerLink]="['/jbc/', 3, 0]" class="button medium mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.view_360_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 3, 1]" class="button medium mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.explore_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 3, 2]" class="button medium mr-5 mt-3 mx-auto mb-3">{{pageContents?.activelanguage?.constant_values?.enquire_button_lable}}</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-5  mtminus_left zindex99 onlyweb">
                     <div class="min_h_img min_h untouch align_center" >
                         <img class="w-100" [defaultImage]="'assets/media/images/our-towers-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.jbc_3_block_img">
                     </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray">
        <div class="container ">
            <div class="row align_vcenter">
                <div class="col-md-5  zindex99 mtminus_right">
                     <div class="min_h_img min_h align_center" >
                         <img class="w-100" [defaultImage]="'assets/media/images/our-towers-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.jbc_4_block_img">
                     </div>
                </div>
                <div class="col-md-7 text-center  mtplus_right min_h400">
                    <div class="border_big_white min_h400 p-4 pfull_0 arabic-center">
                        <img class="img-fluid m-auto " src="assets/media/images/jbclogo.svg">
                        <h3 class="theme_color text-center arabic-center">JBC-4</h3>
                        <div class="row col-md-12 text-center max-75 mfull_0">
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/floors.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc4_floors}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_floor_lable}}</p>
                            </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/office_units.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc4_office_units}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_office_units_lable}}</p>
                            </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/retail_area.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc4_retail_area}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_retail_area_lable}}</p>
                                    </div>
                            </div>
                        </div>
                        <p class="black_color  mt-3 mb-3 text-justify new_padder f13 col-md-12 mfull_0">{{pageContents?.activelanguage?.constant_values?.towers_jbc4_content}}</p>
                        <div class="row col-md-12 max-90 text-center new_padder mfull_0">
                            <a [routerLink]="['/jbc/', 4, 0]" class="button medium ml-5 mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.view_360_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 4, 1]" class="button medium mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.explore_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 4, 2]" class="button medium mr-5 mt-3 mx-auto mb-3">{{pageContents?.activelanguage?.constant_values?.enquire_button_lable}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray p-5">
        <div class="container ">
            <div class="row align_vcenter">
                <div class="col-md-5   mtminus_left zindex99 onlymobile">
                     <div class="min_h_img min_h align_center" >
                          <img class="w-100" [defaultImage]="'assets/media/images/our-towers-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.jbc_5_block_img">
                     </div>
                </div>
                <div class="col-md-7 text-center  min_h400">
                    <div class="border_big_white min_h400 p-4 pfull_0 arabic-center">
                        <img class="img-fluid m-auto " src="assets/media/images/jbclogo.svg">
                        <h3 class="theme_color text-center arabic-center">JBC-5</h3>
                        <div class="row col-md-12 text-center max-75 mfull_0">
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/floors.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc5_floors}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_floor_lable}}</p>
                                </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/office_units.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc5_office_units}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_office_units_lable}}</p>
                                </div>
                            </div>
                            <div class="col-md-4  p-2 ">
                                <div class="border_light border_w_2">
                                <img class="img-fluid p-3" src="https://jbc-assets.s3.amazonaws.com/images/retail_area.png">
                                <p class="theme_color m-0 f12 font-weight-bold">{{pageContents?.activelanguage?.constant_values?.towers_jbc5_retail_area}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</p>
                                <p class="theme_color m-0 f12">{{pageContents?.activelanguage?.constant_values?.towers_retail_area_lable}}</p>
                                    </div>
                            </div>
                        </div>
                        <p class="black_color  mt-3 mb-3 new_padder text-justify f13 col-md-12 mfull_0">{{pageContents?.activelanguage?.constant_values?.towers_jbc5_content}}</p>
                        <div class="row col-md-12 max-90 text-center new_padder mfull_0">
                            <a [routerLink]="['/jbc/', 5, 0]" class="button medium mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.view_360_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 5, 1]" class="button medium mr-5 mt-3 mx-auto">{{pageContents?.activelanguage?.constant_values?.explore_button_lable}}</a>
                            <a [routerLink]="['/jbc/', 5, 2]" class="button medium mr-5 mt-3 mx-auto mb-3">{{pageContents?.activelanguage?.constant_values?.enquire_button_lable}}</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-5   mtminus_left zindex99 onlyweb">
                     <div class="min_h_img min_h untouch align_center">
                         <img class="w-100" [defaultImage]="'assets/media/images/our-towers-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.jbc_5_block_img">
                     </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_white p-5">
        <div class="container ">
            <div class="row">
                <div class="col-sm-12 text-center arabic-center">
                    <a href="assets/media/docs/JBC-Brochure.pdf" download="JBC-Brochure.pdf" class="button button_trans mt-2"><img class="cus_image" src="https://jbc-assets.s3.amazonaws.com/images/download brochure.png">{{pageContents?.activelanguage?.constant_values?.download_brouchure_btn_lable}}</a>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray p-5">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 border_bottom arabic-center">{{pageContents?.activelanguage?.constant_values?.locations_lable}}</h1>
                <div class="">
                    <ul class="nav nav-pills mb-3  theme_nav nav-fill">
                        <li class="nav-item p-0" (click)="changeTab(1)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 1 }">JBC1</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(2)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 2 }">JBC2</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(3)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 3 }">JBC3</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(4)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 4 }">JBC4</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(5)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 5 }">JBC5</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <div>
                                <google-map height="450" width="100%" [zoom]="zoom" [center]="center" [options]="options">
                                    <map-marker *ngFor="let marker of markers" #somemarker="mapMarker" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfoWindow(somemarker,marker.title)">
                                    </map-marker>
                                    <map-info-window>
                                        <h5 class="title full-width text-dark"> Jumeirah Business Centre - {{activetab}}
                                        </h5>
                                        <div class="address">
                                            <div class="address-line full-width text-muted">Jumeirah Lakes Towers -
                                                Dubai</div>
                                            <div class="address-line full-width text-muted">United Arab Emirates</div>
                                        </div>
                                        <a class="address-line full-width text-primary" target="_blank"
                                            href="{{infoCoords.link}}">View on Google Maps</a>
                                    </map-info-window>
                                </google-map>
                            </div>
                            <div class="theme_bg_color pre-wrap arabic-center">
                                {{ pageContents?.activelanguage?.constant_values['towers_jbc_address_' + activetab] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>