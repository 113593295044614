import { Component, OnInit , Renderer2} from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
  }

  closeMenu(){
  	this.renderer.removeClass(document.body,'is-menu-visible');
  	this.renderer.setStyle(document.body, 'overflow-y', 'scroll');
  }

}
