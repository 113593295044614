import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { NgxSpinnerService } from "ngx-spinner";
import { NotifierService } from "angular-notifier";
import { GoogleMapsModule, MapInfoWindow, MapMarker, GoogleMap } from "@angular/google-maps";

@Injectable({
	providedIn: "root"
})
export class WebService {
	httpOptions: any;
	activelanguage: number = 1;
	zoom: number = environment.mapZoom;
	imgBaseUrl: string = environment.ImageUrl;
	View360Url: string = environment.View360Url;;
	slider1Config: any = environment.slider1Config;
	thumb1Config:any = environment.thumb1Config;
	slider2Config:any = environment.slider2Config;
	thumb2Config:any = environment.thumb2Config;
	benefitSliderConfig:any = environment.benefitSliderConfig;
	previousUrl: string = '';
	options: google.maps.MapOptions = {
		mapTypeControl: true,
		disableDefaultUI: true,
		draggable: false
	};
	settingsInfo : any;
	constructor(
		private httpClient: HttpClient,
		private spinner: NgxSpinnerService,
		private notifier:NotifierService
	) {
		if (localStorage.getItem("jbc_active_language") === null) {
			this.activelanguage = 1;
			localStorage.setItem("jbc_active_language", this.activelanguage.toString());
		} else {
			this.activelanguage = Number(localStorage.getItem("jbc_active_language"));
		}
	}

	public settingsCall() {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/settings/` + this.activelanguage,
			{}
		);
	}

	public homeContent() {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/home/content/` + this.activelanguage,
			{}
		);
	}

	public aboutusContent() {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/aboutus/content/` + this.activelanguage,
			{}
		);
	}

	public towersContent() {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/towers/content/` + this.activelanguage,
			{}
		);
	}

	public buyContent() {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/buy/content/` + this.activelanguage,
			{}
		);
	}

	public leaseContent() {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/lease/content/` + this.activelanguage,
			{}
		);
	}

	public mediaContent() {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/media/content/` + this.activelanguage,
			{}
		);
	}

	public contactusContent() {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/contactus/content/` + this.activelanguage,
			{}
		);
	}

	public jbcContent(id: number) {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/jbc/` + id + `/` + this.activelanguage,
			{}
		);
	}

	public blogInfo(id: number) {
		return this.httpClient.get<any>(
			`${environment.apiUrl}/blog/` + id,
			{}
		);
	}

	public enquiry(formData: any) {
		return this.httpClient.post<any>(`${environment.apiUrl}/enquiry`, formData, {});
	}

	public brokerenquiry(formData: any) {
		return this.httpClient.post<any>(`${environment.apiUrl}/enquiry/broker`, formData, {});
	}

	public subscribe(formData: any) {
		return this.httpClient.post<any>(`${environment.apiUrl}/subscribe`, formData, {});
	}

	public getMapInfo(tab: number) {
		return environment.jbcMapinfo[tab];
	}

	public getMapMarkers(tab: number, center: any) {
		return [
			{
				position: center,
				label: {
					color: "#C70E20",
					fontSize: "16px",
					fontWeight: "bold",
					textShadow: "2px 2px #ff0000",
					text: "JBC - " + tab,
					labelClass: "marker-label"
				},
				options: {
					draggable: false,
					icon: {
						labelOrigin: new google.maps.Point(20, 110),
						url: "https://jbc-assets.s3.amazonaws.com/images/g-map/jbc" + tab + ".png"
					},
				}
			}
		];
	}

	showLoader() {
		this.spinner.show();
	}

	hideLoader() {
		this.spinner.hide();
	}

	showNotification( type: string, message: string ) {
		this.notifier.notify( type, message );
	}
}
