<!-- Banner Start-->
<section id="banner" class="major jbc1_banner" [defaultImage]="'assets/media/images/banner-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.banner_img">
    <div class="inner">
    </div>
</section>
<!-- Banner End-->

<!-- Main Start-->
<div id="main">
    <div class="bg_gray p-5 ">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color arabic-center">{{pageContents?.activelanguage?.constant_values?.jbc_full_lable}} - {{jbcid}} </h1>
            </div>
        </div>
    </div>
    <div class="bg_white  p-5">
        <div class="container ">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h2 class="theme_color mb-2 arabic-center">{{pageContents?.activelanguage?.constant_values?.overview_lable}}</h2>
                    <p class="theme_color m-0 f18 pre-wrap">{{pageContents?.overviewContent}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray  p-3">
        <div class="container p-0">
            <h2 class="theme_color mb-2 text-center arabic-center">{{pageContents?.activelanguage?.constant_values?.features_lable}}</h2>
            <div class="row">
                <div class="col-md-7  mt-0 zindex99 onlymobile">
                    <img class="img-fluid pl-3 pr-0 mobile_pad" [defaultImage]="'assets/media/images/contact-block-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.feature_img_1">
                </div>
                <div class="col-md-5 text-justify theme_color dflex_div f16 pre-wrap" [innerHtml]="pageContents?.featureContent1"></div>
                <div class="col-md-7  mt-0 zindex99 onlyweb">
                    <img class="img-fluid pl-3 pr-0 mobile_pad" [defaultImage]="'assets/media/images/contact-block-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.feature_img_1">
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray  p-3">
        <div class="container p-0">
            <div class="row">
                <div class="col-md-7  mt-0 zindex99">
                    <img class="img-fluid pl-0 pr-3 mobile_pad" [defaultImage]="'assets/media/images/contact-block-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.feature_img_2">
                </div>
                <div class="col-md-5 text-justify theme_color dflex_div f16 pre-wrap" [innerHtml]="pageContents?.featureContent2"></div>
            </div>
        </div>
    </div>
    <div class="bg_gray  p-3">
        <div class="container p-0">
            <div class="row">
                <div class="col-md-7  mt-0 zindex99 onlymobile">
                    <img class="img-fluid pl-3 pr-0 mobile_pad" [defaultImage]="'assets/media/images/contact-block-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.feature_img_3">
                </div>
                <div class="col-md-5 text-justify  theme_color dflex_div f16 pre-wrap" [innerHtml]="pageContents?.featureContent3"></div>
                <div class="col-md-7  mt-0 zindex99 onlyweb">
                    <img class="img-fluid pl-3 pr-0 mobile_pad" [defaultImage]="'assets/media/images/contact-block-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.feature_img_3">
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray  p-3">
        <div class="container p-0">
            <div class="row">
                <div class="col-md-7  mt-0 zindex99">
                    <img class="img-fluid pl-0 pr-3 mobile_pad" [defaultImage]="'assets/media/images/contact-block-dummy.jpg'" [lazyLoad]="imgBaseUrl + pageContents?.feature_img_4">
                </div>
                <div class="col-md-5 text-justify theme_color dflex_div f16 pre-wrap" [innerHtml]="pageContents?.featureContent4"></div>
            </div>
        </div>
    </div>
    <div class="bg_white p-5" id="View360">
        <div class="container p-0">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 arabic-center">{{pageContents?.activelanguage?.constant_values?.view_360_button_lable}}</h1>
                <div class="col-md-12">
                    <iframe [src]="View360Url" width="100%" height="
                    670px"></iframe>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray p-5">
        <div class="container p-0">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 arabic-center">{{pageContents?.activelanguage?.constant_values?.image_gallery_lable}}</h1>
                <div class="col-md-12">
                    <ngx-slick-carousel class="carousel slider-for-2" #slickModal="slick-carousel" [config]="slider2Config">
                        <div ngxSlickItem *ngFor="let slide of pageContents?.image_gallery; let i = index" class="slide">
                            <img [src]="imgBaseUrl + slide" alt="" width="100%" (click)="open(i)">
                        </div>
                    </ngx-slick-carousel>

                    <ngx-slick-carousel class="carousel slider-nav-2" #slickModal="slick-carousel" [config]="thumb2Config">
                        <div ngxSlickItem *ngFor="let slide of pageContents?.image_gallery" class="slide">
                            <img [src]="imgBaseUrl + slide" alt="" width="100%">
                        </div>
                    </ngx-slick-carousel>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="bg_white  p-3">
        <div class="container p-0">
            <div class="row">
                <div class="col-md-4 mt-0 zindex99">
                    <h2 class="theme_color mb-2 text-center">{{pageContents?.activelanguage?.constant_values?.floor_plan_lable}}</h2>
                    <div class="mt-4">
                        <img class="img-fluid pl-0 pr-3" [src]="imgBaseUrl + pageContents?.floor_plan_img">
                    </div>
                </div>
                <div class="col-md-12 text-center  theme_color">
                    <h2 class="theme_color mb-2 text-center">{{pageContents?.activelanguage?.constant_values?.key_features_lable}}</h2>
                    <div class="mt-5 p-1 pt-3 pb-3 border_big f18">
                        <table class="text-left table tablecus col-md-12 mfull_0">
                            <tr>
                                <td>{{pageContents?.activelanguage?.constant_values?.type_lable}}</td>
                                <td>Commercial Freehold</td>
                            </tr>
                            <tr>
                                <td>{{pageContents?.activelanguage?.constant_values?.floors_lable}}</td>
                                <td>{{pageContents?.activelanguage?.constant_values['towers_jbc'+jbcid+'_floors']}}</td>
                            </tr>
                            <tr>
                                <td>{{pageContents?.activelanguage?.constant_values?.office_units_lable}}</td>
                                <td>{{pageContents?.activelanguage?.constant_values['towers_jbc'+jbcid+'_office_units']}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</td>
                            </tr>
                            <tr>
                                <td>{{pageContents?.activelanguage?.constant_values?.retail_area_lable}}</td>
                                <td>{{pageContents?.activelanguage?.constant_values['towers_jbc'+jbcid+'_retail_area']}} {{pageContents?.activelanguage?.constant_values?.sq_ft_lable}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="bg_gray  p-3">
        <div class="container p-0">
            <div class="row">
                <div class="col-md-8 mt-0 zindex99 onlymobile">
                    <video width="100%" [src]="pageContents?.video_url" [muted]="true" [volume]="0.2" playsinline poster="https://jbc-assets.s3.amazonaws.com/images/poster.jpg" controls>
                </video>
                </div>
                <div class="col-md-4 text-center  theme_color dflex_div dlfex_column f16">
                    <h1 class="theme_color arabic-center"> {{pageContents?.activelanguage?.constant_values?.watch_video_lable}} </h1>
                    {{pageContents?.videoContent}}
                </div>
                <div class="col-md-8 mt-0 zindex99 onlyweb">
                    <video width="100%" [src]="pageContents?.video_url" [muted]="true" [volume]="0.2" playsinline poster="https://jbc-assets.s3.amazonaws.com/images/poster.jpg" controls>
                </video>
                </div>
            </div>
        </div>
    </div>

    <div class="bg_white p-4">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mt-5 mb-3 arabic-center">{{pageContents?.activelanguage?.constant_values?.buy_its_your_location_lable}}</h1>
                </div>
        </div>
    </div>

    <div id="your-location">
        <div class="">
            <img [defaultImage]="'assets/media/images/location-dummy.jpg'" [lazyLoad]="'https://jbc-assets.s3.amazonaws.com/images/Its-your-location.jpg'" class="img-fluid">
        </div>
    </div>
    
    <div class="bg_white p-5">
        <div class="container p-0">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 arabic-center">{{pageContents?.activelanguage?.constant_values?.buy_aminities_label}}</h1>
                <div class="borderdiv mb-2"></div>
                <div class="box_white row">
                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/golf course.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.golf_course_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/metro station.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.metro_station_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/banks and ATM.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.banks_and_atm_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/hotels.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.hotels_lable}}</h5>
                    </div>


                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/cafe & restaurants.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.cafe_restaurants_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/retail & convenience stores.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.retail_convenience_stores_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/clinics & pharmacy.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.clinics_pharmacy_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/fitness centre.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.fitness_centre_lable}}</h5>
                    </div>


                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/education & child care.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.education_child_care_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/lakes & park.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.lakes_park_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/spa & beauty saloon.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.spa_beauty_saloon_lable}}</h5>
                    </div>

                    <div class="col-sm-3 arabic-center">
                        <img class="img-fluid p-2" src="https://jbc-assets.s3.amazonaws.com/images/amenities/dry cleaning.png">
                        <h5 class="theme_color">{{pageContents?.activelanguage?.constant_values?.dry_cleaning_lable}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg_gray p-5">
        <div class="container ">
            <div class="inner text-center">
                <h1 class="theme_color mb-2 border_bottom arabic-center">{{pageContents?.activelanguage?.constant_values?.locations_lable}}</h1>
                <div class="">
                    <ul class="nav nav-pills mb-3  theme_nav nav-fill">
                        <li class="nav-item p-0" (click)="changeTab(1)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 1 }">JBC1</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(2)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 2 }">JBC2</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(3)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 3 }">JBC3</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(4)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 4 }">JBC4</a>
                        </li>
                        <li class="nav-item p-0" (click)="changeTab(5)">
                            <a class="nav-link font-weight-bold" [ngClass]="{ 'active': activetab  == 5 }">JBC5</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="tab-pane fade show active">
                            <div>
                                <google-map height="450" width="100%" [zoom]="zoom" [center]="center" [options]="options">
                                    <map-marker *ngFor="let marker of markers" #somemarker="mapMarker" [position]="marker.position" [label]="marker.label" [title]="marker.title" [options]="marker.options" (mapClick)="openInfoWindow(somemarker,marker.title)">
                                    </map-marker>
                                    <map-info-window>
                                        <h5 class="title full-width text-dark"> Jumeirah Business Centre - {{activetab}}
                                        </h5>
                                        <div class="address">
                                            <div class="address-line full-width text-muted">Jumeirah Lakes Towers -
                                                Dubai</div>
                                            <div class="address-line full-width text-muted">United Arab Emirates</div>
                                        </div>
                                        <a class="address-line full-width text-primary" target="_blank"
                                            href="{{infoCoords.link}}">View on Google Maps</a>
                                    </map-info-window>
                                </google-map>
                            </div>
                            <div class="theme_bg_color pre-wrap arabic-center">
                                {{ pageContents?.activelanguage?.constant_values['towers_jbc_address_' + activetab] }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Main End-->