import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { GoogleMapsModule, MapInfoWindow, MapMarker, GoogleMap } from "@angular/google-maps";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { WebService } from "../../service/web.service";
import { Lightbox } from 'ngx-lightbox';

@Component({
	selector: "app-jbc",
	templateUrl: "./jbc.component.html",
	styleUrls: ["./jbc.component.css"]
})
export class JbcComponent implements OnInit {
	@ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
	zoom: number;
	options: any;
	center: any;
	markers: any;
	loading: boolean = true;
	imgBaseUrl: string;
	activetab: number;
	jbcid: number;
	Base360Url: any;
	pageContents: any;
	View360Url: SafeResourceUrl;
	slider1Config: any;
	thumb1Config: any;
	slider2Config: any;
	thumb2Config: any;
	galleryItems: any = [];
	infoCoords: any = { link: '' };

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private webService: WebService,
		private sanitizer: DomSanitizer,
		private lightbox: Lightbox
	) { }

	ngOnInit(): void {
		this.zoom = this.webService.zoom;
		this.options = this.webService.options;
		this.imgBaseUrl = this.webService.imgBaseUrl;
		this.Base360Url = this.webService.View360Url;
		this.slider1Config = this.webService.slider1Config;
		this.thumb1Config = this.webService.thumb1Config;
		this.slider2Config = this.webService.slider2Config;
		this.thumb2Config = this.webService.thumb2Config;
		this.activatedRoute.params.subscribe(params => this.getjbcInfo(params));
	}

	convertUrl() {
		return this.sanitizer.bypassSecurityTrustResourceUrl(
			this.Base360Url + this.jbcid
		);
	}

	getjbcInfo(params) {
		this.jbcid = params.id;
		this.activetab = params.id;

		this.webService.jbcContent(this.jbcid).subscribe(
			resultData => {
				this.pageContents = resultData;
				for (let i = 0; i < this.pageContents.image_gallery.length; i++) {
					let obj = {
						src: this.imgBaseUrl + this.pageContents.image_gallery[i],
						thumb: this.imgBaseUrl + this.pageContents.image_gallery[i],
						caption: 'Gallery Item ' + (i + 1)
					};
					this.galleryItems.push(obj);
				}
				this.pageContents.overviewContent =
					resultData.activelanguage.constant_values[
					"jbc" + this.jbcid + "_overview_content"
					];
				this.pageContents.featureContent1 =
					resultData.activelanguage.constant_values[
					"jbc" + this.jbcid + "_feature_content_1"
					];
				this.pageContents.featureContent2 =
					resultData.activelanguage.constant_values[
					"jbc" + this.jbcid + "_feature_content_2"
					];
				this.pageContents.featureContent3 =
					resultData.activelanguage.constant_values[
					"jbc" + this.jbcid + "_feature_content_3"
					];
				this.pageContents.featureContent4 =
					resultData.activelanguage.constant_values[
					"jbc" + this.jbcid + "_feature_content_4"
					];
				this.pageContents.videoContent =
					resultData.activelanguage.constant_values[
					"jbc" + this.jbcid + "_video_block_content"
					];
				this.View360Url = this.convertUrl();
				this.changeTab(this.jbcid);
				if(params.position){
					if(params.position == '0'){
						this.scrolltoDiv('View360');
					}else if(params.position == '2'){
						this.scrolltoDiv('contact');
					}else{
						document.body.scrollTop = 0;
					}
				}
			},
			(err: HttpErrorResponse) => {
				this.loading = false;
				this.webService.hideLoader();
				if (err.status == 401) {
					alert(err.error.message);
				}
			}
		);
	}

	changeTab(tab) {
		this.activetab = tab;
		this.center = this.webService.getMapInfo(this.activetab);
		this.markers = this.webService.getMapMarkers(this.activetab, this.center);
		this.loading = false;
		this.webService.hideLoader();
	}

	open(index: number): void {
		this.lightbox.open(this.galleryItems, index, { wrapAround: true, centerVertically: true, disableScrolling: true, alwaysShowNavOnTouchDevices: true });
	}

	close(): void {
		this.lightbox.close();
	}

	openInfoWindow(marker, index) {
		this.infoCoords.link = marker._position.link;
		this.infoWindow.open(marker);
	}

	scrolltoDiv(position) {
		document.getElementById(position).scrollIntoView();
	}
}
