import { Component, OnInit, ViewChild } from "@angular/core";
import { GoogleMapsModule, MapInfoWindow, MapMarker, GoogleMap } from "@angular/google-maps";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { WebService } from "../../service/web.service";

@Component({
  selector: "app-lease",
  templateUrl: "./lease.component.html",
  styleUrls: ["./lease.component.css"]
})
export class LeaseComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  zoom: number;
  options: any;
  center: any;
  markers: any;
  loading = true;
  pageContents: any;
  imgBaseUrl: string;
  activetab: number = 1;
  slider1Config: any;
  thumb1Config: any;
  slider2Config: any;
  thumb2Config: any;
  View360Url: string;
  benefitSliderConfig: any;
  benefits: any;
  infoCoords: any = { link: '' };

  constructor(private webService: WebService) { }
  ngOnInit(): void {
    this.zoom = this.webService.zoom;
    this.options = this.webService.options;
    this.imgBaseUrl = this.webService.imgBaseUrl;
    this.benefitSliderConfig = this.webService.benefitSliderConfig;
    this.getpageContent();
  }

  getpageContent() {
    this.webService.leaseContent().subscribe(
      resultData => {
        this.pageContents = resultData;
        this.benefits = this.pageContents.activelanguage.constant_values.lease_page_benefits_content.split(",");
        this.changeTab(this.activetab);
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.webService.hideLoader();
        if (err.status == 401) {
          alert(err.error.message);
        }
      }
    );
  }

  changeTab(tab) {
    this.activetab = tab;
    this.center = this.webService.getMapInfo(this.activetab);
    this.markers = this.webService.getMapMarkers(this.activetab, this.center);
    this.loading = false;
    this.webService.hideLoader();
  }

  openInfoWindow(marker, index) {
    this.infoCoords.link = marker._position.link;
    this.infoWindow.open(marker);
  }
}
