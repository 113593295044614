import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { WebService } from "../../service/web.service";

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
  loading: boolean = true;
  pageContents: any;
  constructor(private webService: WebService) { }

  ngOnInit(): void {
    this.getpageContent();
  }

  getpageContent() {
    this.webService.buyContent().subscribe(
      resultData => {
        this.pageContents = resultData;
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.webService.hideLoader();
        if (err.status == 401) {
          alert(err.error.message);
        }
      }
    );
  }

}
