import { Component, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { WebService } from "./service/web.service";
import { filter } from 'rxjs/operators';
import { UrlService } from './service/url.service';
import * as $ from "jquery";
import { NgcCookieConsentService, NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  enquireForm: FormGroup;
  subscribeForm: FormGroup;
  loading = true;
  activelanguage: number;
  languageLabel: string = '';
  settingsInfo: any;
  isSubmitted: boolean = false;
  now: number = new Date().getTime();
  scrollHeight: number = 0;
  imgBaseUrl: string;
  previousUrl: string = '';
  currentUrl: string = '';
  thisyear: number = new Date().getFullYear();

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  formData = {
    name: "",
    mobile: "",
    email:"",
    type:"",
    comments:"",
    page_url:window.location.href
  };

  subscriberData = {email:""};

  constructor(
    public router: Router,
    private renderer: Renderer2,
    private webService: WebService,
    private titleService: Title,
    private urlService: UrlService,
    private ccService: NgcCookieConsentService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit() {
    // this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //     console.log('popupOpen');
    //   });

    // this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //     console.log('popuClose');
    //   });

    // this.initializeSubscription = this.ccService.initialize$.subscribe(
    //   (event: NgcInitializeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //     console.log(`initialize: ${JSON.stringify(event)}`);
    //   });

    // this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
    //   (event: NgcStatusChangeEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //     console.log(`statusChange: ${JSON.stringify(event)}`);
    //   });

    // this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
    //   () => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //     console.log(`revokeChoice`);
    //   });

    // this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
    //   (event: NgcNoCookieLawEvent) => {
    //     // you can use this.ccService.getConfig() to do stuff...
    //     console.log(`noCookieLaw: ${JSON.stringify(event)}`);
    //   });

    window.addEventListener("scroll", this.scroll, true);
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      //console.log(this.currentUrl)
      this.previousUrl = this.currentUrl;
      this.currentUrl = event.url;
      this.urlService.setPreviousUrl(this.previousUrl);
      this.webService.previousUrl = this.previousUrl;
      var title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
      if(this.router.url.includes('/jbc/')) {
        title = title + this.router.url.slice(-3);
        title = title.slice(0, -2);
        this.renderer.removeClass(document.body, "banner_less");
      } else if (this.router.url.includes('/blog/')) {
        this.renderer.addClass(document.body, "banner_less");
        document.body.scrollTop = 0;
      } else if (this.router.url.includes('/aboutus')) {
        this.renderer.addClass(document.body, "hide-enquiry");
        document.body.scrollTop = 0;
      } else if (this.router.url.includes('/property-broker')) {
        this.renderer.addClass(document.body, "hide-enquiry");
        document.body.scrollTop = 0;
      } else {
        this.renderer.removeClass(document.body, "banner_less");
        this.renderer.removeClass(document.body, "hide-enquiry");
        document.body.scrollTop = 0;
      }
      this.titleService.setTitle(title + ' - JBC Properties');
      if (!this.settingsInfo) {
        this.getSettings();
        this.imgBaseUrl = this.webService.imgBaseUrl;
        this.activelanguage = this.webService.activelanguage;
      }
      //this.getSettings();
      this.renderer.removeClass(document.body, "is-menu-visible");
      this.renderer.setStyle(document.body, "overflow-y", "scroll");
      window.scrollTo(0, 0);
    });
    this.enquireForm = this.formBuilder.group({
      name: ["", Validators.required],
      mobile: ["", Validators.required],
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      type: ["", Validators.required],
      comments: ["", Validators.required]
    });

    this.subscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
  }

  ngOnDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializeSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }

  get f() { return this.enquireForm.controls; }

  get sf() { return this.subscribeForm.controls; }

  getSettings() {
    this.webService.settingsCall().subscribe(
      resultData => {
        this.loading = false;
        this.settingsInfo = resultData;
        //console.log(resultData)
        this.settingsInfo.languages.forEach(item => {
          if (item.id == this.activelanguage) {
            this.languageLabel = item.lable;
          }
        });
        this.webService.hideLoader();
      },
      (err: HttpErrorResponse) => {
        this.webService.hideLoader();
        this.loading = false;
        if (err.status == 401) {
          alert(err.error.message);
        }
      }
    );
  }

  getTitle(state, parent) {
    var data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

  showMenu() {
    this.renderer.addClass(document.body, "is-menu-visible");
    this.renderer.setStyle(document.body, "overflow-y", "hidden");
  }

  closeMenu() {
    this.renderer.removeClass(document.body, "is-menu-visible");
    this.renderer.setStyle(document.body, "overflow-y", "scroll");
  }

  scroll = (event): void => {
    this.scrollHeight = window.pageYOffset;
  };

  changeLanguage(language) {
    if (this.activelanguage != language) {
      this.activelanguage = Number(language);
      localStorage.setItem("jbc_active_language", language.toString());
      location.reload();
      this.getSettings();
    }
  }

  inputChange(){
    this.isSubmitted = false;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.enquireForm.invalid) {
      return;
    }
    this.webService.showLoader();
    this.webService.enquiry(this.formData).subscribe(
      data => {
        this.webService.hideLoader();
        this.webService.showNotification('success',data['message']);
        this.isSubmitted = false;
        this.formData = {
          name: "",
          mobile: "",
          email:"",
          type:"",
          comments:"",
          page_url:window.location.href
        };
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.webService.hideLoader();
        if (err.status == 401) {
          // /this.webService.showErrorAlert(err.error.message.message[0]);
          this.loading = false;
        }else{
          this.webService.showNotification('error', 'Oops Some internal Server Error');
        }
      }
    );
  }

  onSubscribe() {
    this.isSubmitted = true;
    if (this.subscribeForm.invalid) {
      return;
    }
    this.webService.showLoader();
    this.webService.subscribe(this.subscriberData).subscribe(
      data => {
        this.webService.hideLoader();
        this.webService.showNotification('success',data['message']);
        this.isSubmitted = false;
        this.subscriberData = {email:""};
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        this.webService.hideLoader();
        if (err.status == 401) {
          // /this.webService.showErrorAlert(err.error.message.message[0]);
          this.loading = false;
        }else{
          this.webService.showNotification('error', 'Oops Some internal Server Error');
        }
      }
    );
  }
}
