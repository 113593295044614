import { Component, OnInit, ViewChild } from "@angular/core";
import { GoogleMapsModule, MapInfoWindow, MapMarker, GoogleMap } from "@angular/google-maps";
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { WebService } from '../../service/web.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow;
  loading = true;
  pageContents: any;
  zoom: number;
  options: any;
  center: any;
  markers: any;
  imgBaseUrl: string;
  activetab: number = 1;
  active360: number = 0;
  slider1Config: any;
  thumb1Config: any;
  slider2Config: any;
  thumb2Config: any;
  View360Url: SafeResourceUrl;
  Base360Url: string;
  galleryItems: any = [];
  jbcs = [1, 2, 3, 4, 5];
  infoCoords: any = { link: '' };

  constructor(private webService: WebService, private sanitizer: DomSanitizer, private lightbox: Lightbox) {
  }

  ngOnInit(): void {
    this.zoom = this.webService.zoom;
    this.options = this.webService.options;
    this.imgBaseUrl = this.webService.imgBaseUrl;
    this.Base360Url = this.webService.View360Url;
    this.slider1Config = this.webService.slider1Config;
    this.thumb1Config = this.webService.thumb1Config;
    this.slider2Config = this.webService.slider2Config;
    this.thumb2Config = this.webService.thumb2Config;
    this.getpageContent();
  }

  convertUrl(key) {
    console.log("base360 url ===== ", this.Base360Url, key);
    
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.Base360Url + key);
  }

  getpageContent() {
    this.webService.mediaContent().subscribe((resultData) => {
      this.pageContents = resultData;
      for (let i = 0; i < this.pageContents.gallery.length; i++) {
        let obj = {
          src: this.imgBaseUrl + this.pageContents.gallery[i],
          thumb: this.imgBaseUrl + this.pageContents.gallery[i],
          caption: 'Gallery Item ' + (i + 1)
        };
        this.galleryItems.push(obj);
        // console.log(this.galleryItems,'************', this.pageContents);
        
      }
      this.changeTab(this.activetab);
      this.active360 = this.jbcs[0];
      // console.log('before conversion ======== ', this.View360Url, this.active360);

      this.View360Url = this.convertUrl(this.active360);
      // console.log('after conversion ======== ', this.View360Url, this.jbcs);
      
    }, (err: HttpErrorResponse) => {
      this.loading = false;
      this.webService.hideLoader();
      if (err.status == 401) {
        alert(err.error.message);
      }
    }
    );
  }

  change360Tab(tab) {
    if (tab != this.active360) {
      this.active360 = tab;
      this.View360Url = this.convertUrl(tab);
    }
  }

  changeTab(tab) {
    this.activetab = tab;
    this.center = this.webService.getMapInfo(this.activetab);
    this.markers = this.webService.getMapMarkers(this.activetab, this.center);
    this.loading = false;
    this.webService.hideLoader();
  }

  open(index: number): void {
    this.lightbox.open(this.galleryItems, index, { wrapAround: true, centerVertically: true, disableScrolling: true, alwaysShowNavOnTouchDevices: true });
  }

  close(): void {
    this.lightbox.close();
  }

  openInfoWindow(marker, index) {
    this.infoCoords.link = marker._position.link;
    this.infoWindow.open(marker);
  }

}
