<div id="wrapper" [ngClass]="{ 'arabic-content': activelanguage ===  4 }">
    <ngx-spinner size="large" color="#fff" type="ball-spin-clockwise"></ngx-spinner>
    <notifier-container></notifier-container>
    <!-- Header Start-->
    <!-- <app-header></app-header> -->
    <header id="header" [ngClass]="{ 'alt': scrollHeight < 600 }">
        <div class="container">
            <div class="row">
                <a [routerLink]="['']" class="logo" *ngIf="settingsInfo?.logo"><img
                        [src]="imgBaseUrl + settingsInfo?.logo"></a>
                <nav class="onlyweb">
                    <!-- <span class="language" *ngIf="settingsInfo?.languages">
                        <span *ngFor="let language of settingsInfo.languages; let last = last;" [ngClass]="{ 'font-weight-bold text-body': language.id === activelanguage }" (click)="changeLanguage(language.id)"> {{ language.lable }} 
                            <span *ngIf="!last"> / </span> 
                        </span>
                    </span> -->
                    <span class="language dropdown" *ngIf="settingsInfo?.languages">
                        <span class="dropdown-toggle" data-toggle="dropdown">{{languageLabel ? languageLabel :
                            'Language'}}</span>
                        <span class="dropdown-menu">
                            <span class="dropdown-item"
                                *ngFor="let language of settingsInfo.languages; let last = last;"
                                [ngClass]="{ 'font-weight-bold text-body': language.id === activelanguage }"
                                (click)="changeLanguage(language.id)"> {{ language.lable }}

                            </span>
                        </span>
                    </span>
                    <a class="menu-icon" (click)="showMenu()">

                    </a>
                </nav>
                <div class="onlymobile">
                    <span class="language dropdown" *ngIf="settingsInfo?.languages">
                        <span class="dropdown-toggle" data-toggle="dropdown">{{languageLabel ? languageLabel :
                            'Language'}}</span>
                        <span class="dropdown-menu">
                            <span class="dropdown-item"
                                *ngFor="let language of settingsInfo?.languages; let last = last;"
                                [ngClass]="{ 'font-weight-bold text-body': language.id === activelanguage }"
                                (click)="changeLanguage(language.id)"> {{ language.lable }}

                            </span>
                        </span>
                    </span>
                    <a class="menu-icon" (click)="showMenu()"></a>
                </div>
            </div>
        </div>
    </header>
    <!-- Header Start-->

    <!-- Menu Start-->
    <!-- <app-menu></app-menu> -->
    <nav id="menu" class="p-0">
        <div class="inner">
            <div class="onlyweb row">
                <div class="col-md-2 p-0 menudiv">
                    <a [routerLink]="['/aboutus']" class="border-0 menuanchor">
                        <div class="menu-text">{{settingsInfo?.activelanguage?.constant_values?.about_us_lable}}</div>
                    </a>
                    <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/aboutus.png">
                </div>
                <div class="col-md-2 p-0 menudiv">
                    <a [routerLink]="['/towers']" class="border-0 menuanchor">
                        <div class="menu-text">{{settingsInfo?.activelanguage?.constant_values?.our_towers_lable}}</div>
                    </a>
                    <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/towers.png">
                </div>
                <div class="col-md-2 p-0 menudiv">
                    <a [routerLink]="['/buy']" class="border-0 menuanchor">
                        <div class="menu-text">{{settingsInfo?.activelanguage?.constant_values?.buy_lable}}</div>
                    </a>
                    <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/buy.png">
                </div>
                <div class="col-md-2 p-0 menudiv">
                    <a [routerLink]="['/lease']" class="border-0 menuanchor">
                        <div class="menu-text">{{settingsInfo?.activelanguage?.constant_values?.lease_lable}}</div>
                    </a>
                    <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/lease.png">
                </div>
                <div class="col-md-2 p-0 menudiv">
                    <a [routerLink]="['/media']" class="border-0 menuanchor">
                        <div class="menu-text">{{settingsInfo?.activelanguage?.constant_values?.media_lable}}</div>
                    </a>
                    <img class="img-fluid mt-1 w100" src="https://jbc-assets.s3.amazonaws.com/images/menu/media.png">
                </div>
                <div class="col-md-2 p-0 menudiv">
                    <a [routerLink]="['/contactus']" class="border-0 menuanchor">
                        <div class="menu-text">{{settingsInfo?.activelanguage?.constant_values?.contact_us_lable}}</div>
                    </a>
                    <img class="img-fluid mt-1 w100"
                        src="https://jbc-assets.s3.amazonaws.com/images/menu/contactus.png">
                </div>
            </div>
            <ul class="links onlymobile">
                <li><a [routerLink]="['']">{{settingsInfo?.activelanguage?.constant_values?.home_lable}}</a></li>
                <li><a [routerLink]="['/aboutus']">{{settingsInfo?.activelanguage?.constant_values?.about_us_lable}}</a>
                </li>
                <li><a
                        [routerLink]="['/towers']">{{settingsInfo?.activelanguage?.constant_values?.our_towers_lable}}</a>
                </li>
                <li><a [routerLink]="['/buy']">{{settingsInfo?.activelanguage?.constant_values?.buy_lable}}</a></li>
                <li><a [routerLink]="['/lease']">{{settingsInfo?.activelanguage?.constant_values?.lease_lable}}</a></li>
                <li><a [routerLink]="['/media']">{{settingsInfo?.activelanguage?.constant_values?.media_lable}}</a></li>
                <li><a
                        [routerLink]="['/contactus']">{{settingsInfo?.activelanguage?.constant_values?.contact_us_lable}}</a>
                </li>
            </ul>
        </div>
        <a class="close" (click)="closeMenu()">Close</a>
    </nav>
    <!-- Menu End-->

    <!-- Center Content -->
    <router-outlet></router-outlet>
    <!-- Center Content -->


    <!-- Contact Start-->
    <div id="contact" class="p-5">
        <div class="container">
            <h1 class="theme_color mb-5 text-center arabic-center">
                {{settingsInfo?.activelanguage?.constant_values?.enquire_now_lable}}
            </h1>
            <form [formGroup]="enquireForm" (ngSubmit)="onSubmit()">
                <fieldset class="primaryborder p-3">
                    <legend class="contact_legent mb-5"><strong
                            class="primary_color">{{settingsInfo?.activelanguage?.constant_values?.Let_us_know_lable}}</strong>
                    </legend>
                    <div class="row pl-5 pr-5">
                        <div class="col-sm-4 mb-5">
                            <div class="field half">
                                <label class="primary_color"
                                    for="name">{{settingsInfo?.activelanguage?.constant_values?.name_lable}} <small class="float-right text-danger" *ngIf="f.name.errors && isSubmitted"> * Required</small></label>
                                <input type="text" formControlName="name" [(ngModel)]="formData.name" (change)="inputChange()">
                            </div>
                        </div>
                        <div class="col-sm-4 mb-5">
                            <div class="field half">
                                <label class="primary_color"
                                    for="mobile">{{settingsInfo?.activelanguage?.constant_values?.mobile_no_lable}} <small class="float-right text-danger" *ngIf="f.mobile.errors && isSubmitted"> * Required</small></label>
                                <input type="text" formControlName="mobile" [(ngModel)]="formData.mobile" (change)="inputChange()">
                            </div>
                        </div>
                        <div class="col-sm-4 mb-5">
                            <div class="field half">
                                <label class="primary_color"
                                    for="email">{{settingsInfo?.activelanguage?.constant_values?.email_address_lable}} <small class="float-right text-danger" *ngIf="f.email.errors && isSubmitted"> * Required</small></label>
                                <input type="email" formControlName="email" [(ngModel)]="formData.email" (change)="inputChange()">
                            </div>
                        </div>
                        <div class="col-sm-4 mb-5">
                            <div class="field half">
                                <label class="primary_color"
                                    for="purchase">{{settingsInfo?.activelanguage?.constant_values?.purchase_or_lease_lable}} <small class="float-right text-danger" *ngIf="f.type.errors && isSubmitted"> * Required</small></label>
                                <input type="text" formControlName="type" [(ngModel)]="formData.type" (change)="inputChange()">
                            </div>
                        </div>
                        <div class="col-sm-8 mb-5">
                            <div class="field half">
                                <label class="primary_color"
                                    for="comment">{{settingsInfo?.activelanguage?.constant_values?.comments_lable}} <small class="float-right text-danger" *ngIf="f.comments.errors && isSubmitted"> * Required</small></label>
                                <input type="text" formControlName="comments" [(ngModel)]="formData.comments" (change)="inputChange()">
                            </div>
                        </div>
                        <div class="col-sm-12 text-center mb-3">
                            <button type="submit"
                                id="SUBMIT">{{settingsInfo?.activelanguage?.constant_values?.submit_query_btn_lable}}</button>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
    <!-- Contact End-->
    <!-- Footer Start-->
    <!-- <app-footer></app-footer> -->
    <footer id="footer" class="bg_gray">
        <div class="container text-center">
            <ul class="icons">
                <li><a [attr.href]="settingsInfo?.fb_link" target="_blank" class="icon brands alt"><i
                            class="fa fa-facebook"></i></a></li>
                <li><a [attr.href]="settingsInfo?.linked_in_link" target="_blank" class="icon brands alt"><i
                                class="fa fa-linkedin"></i></a></li>
                <li><a [attr.href]="settingsInfo?.insta_link" target="_blank" class="icon brands alt"><i
                            class="fa fa-instagram"></i></a></li>
                <li><a [attr.href]="settingsInfo?.twitter_link" target="_blank" class="icon brands alt"><i
                            class="fa fa-twitter"></i></a></li>
                <li><a [attr.href]="settingsInfo?.youtube_link" target="_blank" class="icon brands alt"><i
                            class="fa fa-youtube-play"></i></a></li>
            </ul>
            <div class="row">
                <div class="col-sm-6 col-lg-3 col-md-3 text-left primary_color foot_links">
                    <h4 class="mb-2 primary_color">{{settingsInfo?.activelanguage?.constant_values?.quick_link_lable}}
                    </h4>
                    <ul>
                        <li><a [routerLink]="['']">{{settingsInfo?.activelanguage?.constant_values?.home_lable}}</a>
                        </li>
                        <li><a [routerLink]="['/aboutus']">{{settingsInfo?.activelanguage?.constant_values?.about_us_lable}}</a>
                        </li>
                        <li><a [routerLink]="['/towers']">{{settingsInfo?.activelanguage?.constant_values?.our_towers_lable}}</a>
                        </li>
                        <li><a [routerLink]="['/buy']">{{settingsInfo?.activelanguage?.constant_values?.buy_lable}}</a>
                        </li>
                        <li><a [routerLink]="['/lease']">{{settingsInfo?.activelanguage?.constant_values?.lease_lable}}</a>
                        </li>
                        <li><a [routerLink]="['/media']">{{settingsInfo?.activelanguage?.constant_values?.media_lable}}</a>
                        </li>
                        <li><a [routerLink]="['/contactus']">{{settingsInfo?.activelanguage?.constant_values?.contact_us_lable}}</a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-6 col-lg-3 col-md-3 text-left primary_color foot_links">
                    <h4 class="mb-2 primary_color">{{settingsInfo?.activelanguage?.constant_values?.contact_us_lable}}
                    </h4>
                    <ul>
                        <li><a>{{settingsInfo?.activelanguage?.constant_values?.tel_lable}}: {{settingsInfo?.phone}}</a>
                        </li>
                        <li><a href="mailto:{{settingsInfo?.email}}">{{settingsInfo?.activelanguage?.constant_values?.email_lable}}:
                                {{settingsInfo?.email}}</a></li>
                    </ul>
                </div>
                <div class="col-sm-6 col-lg-3 col-md-3 text-left primary_color foot_links">
                    <h4 class="mb-2 primary_color">{{settingsInfo?.activelanguage?.constant_values?.address_lable}}</h4>
                    <ul>
                        <li class="pre-wrap">{{settingsInfo?.activelanguage?.constant_values?.address}}</li>
                    </ul>
                </div>
                <div class="col-sm-6 col-lg-3 col-md-3 text-left primary_color foot_links">
                    <h4 class="mb-2 primary_color">{{settingsInfo?.activelanguage?.constant_values?.subscribe_lable}}
                    </h4>
                    <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribe()">
                    <div class="row subscribe">
                        
                        <div class="pdn pr-0" [ngClass]="activelanguage == 6 ? 'col-7 col-md-7 col-lg-7' : 'col-8 col-md-7 col-lg-8'">
                            <input type="email" formControlName="email" [(ngModel)]="subscriberData.email" class="bdr_primary subscribe_text"
                                placeholder="{{settingsInfo?.activelanguage?.constant_values?.email_place_holder}}">
                        </div>
                        <div class="text-right pl-1" [ngClass]="activelanguage == 6 ? 'col-5 col-md-5 col-lg-5' : 'col-4 col-md-5 col-lg-4'">
                            <button type="submit" class="sub_pd" [ngClass]="{'width_90': activelanguage == 6 }" [disabled]="sf.email.errors">{{settingsInfo?.activelanguage?.constant_values?.submit_btn_lable}}</button>
                        </div>
                    
                    </div>
                </form>
                </div>
            </div>
        </div>
    </footer>
    <div class="p-3 primary_bg_color mbl_pd">
        <div class="container ">
            <div class="row">
                <div class="white_color col-sm-6 text-left mobile-text pl-0">© {{thisyear}}
                    {{settingsInfo?.activelanguage?.constant_values?.rights_info}}</div>
                <div class="white_color col-sm-6 text-right mobile-text pr-0 tandc-text">
                    <a class="footer-link" [routerLink]="['/terms-conditions']">
                        {{settingsInfo?.activelanguage?.constant_values?.tandc_lable}} </a>
                    <!-- | <a class="footer-link"
                        [routerLink]="['/privacy-policy']">{{settingsInfo?.activelanguage?.constant_values?.privacy_policy_lable}}</a> -->
                </div>
            </div>
        </div>
    </div>
    <!-- Footer Start-->
</div>